//Components
import Slider from "react-slick";
import Icon from "./Icon";
import Button from "./Button";

//Hooks and helpers
import {decode} from "html-entities";

function SliderNextArrow({ className, style, onClick }) {
    return (
        <div className={`cursor-pointer z-0 ${className}`} style={{ ...style }} onClick={onClick}>
            <Icon name="arrow-right-line" size="2xl" />
        </div>
    );
}

function SliderPrevArrow({ className, style, onClick }) {
    return (
        <div className={`cursor-pointer z-0 ${className}`} style={{ ...style }} onClick={onClick}>
            <Icon name="arrow-left-line" size="2xl" />
        </div>
    );
}

const stripHTML = (html) => {
    const regex = /(<([^>]+)>)/ig;
    return html.replace(regex, '');
}

const PostSlider = ({posts, accent}) => {

    var settings = {
        dots: true,
        infinite: true,
        arrows: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SliderNextArrow />,
        prevArrow: <SliderPrevArrow />,
    };

    return (
        <>
            { posts && posts.length > 0 && (
                <Slider className="slider--posts" {...settings} >
                    { posts.map( post =>
                        <div key={post.id}>
                            <div
                                className={`w-full min-h-96 sm:min-h-128 flex items-end bg-no-repeat bg-cover bg-center`}
                                style={ post.featured_image_url ? {backgroundImage: `url(${post.featured_image_url})`} : {}}
                            >

                            <div className={`bg-${accent}/80 text-white lg:w-2/3 py-5 px-5 sm:py-8 sm:px-10`}>
                                <h3 className={`text-base sm:text-xl`}>{decode(post.title.rendered)}</h3>
                                <p className={`text-sm sm:text-base`}>{stripHTML(decode(post.excerpt.rendered))}</p>

                            <Button
                                type={"slider"}
                                state={{id: `${post.id}`}}
                                url={`/news/${post.slug}`}
                                accent={accent}
                                title={`Read more`}
                                iconName={"arrow-right-line"}
                                iconSize={"lg"}
                            />
                            </div>
                        </div>
                    </div>
                )}
            </Slider>
            )}
        </>
    );
}

export default PostSlider;
