//React
import { useState } from "react";

export default function useForm({ form, additionalData, endpointUrl }) {
    const [status, setStatus] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = async(e) => {
        if (form) {
            e.preventDefault();
            setStatus("loading");
            setMessage("");

            const finalFormEndpoint = endpointUrl || form.action;
            const data = Array.from(form.elements)
                .filter((input) => input.name)
                .reduce(
                    (obj, input) => Object.assign(obj, { [input.name]: input.value }),
                    {}
                );

            if (additionalData) {
                Object.assign(data, additionalData);
            }

            if (!data.recaptcha || data.recaptcha === "") {
                setMessage("Please verify that you are not a robot.");
                setStatus("error");

                return;
            }
        

            const req = await fetch(finalFormEndpoint, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            }).catch((err) => {
                console.log(err)
            });

            if (req.status === 200) {
                setStatus("success");
                setMessage("You will receive an email shortly to confirm your subscription.");
            } else if (req.status === 409) {
                setStatus("error");
                setMessage("You are already subscribed to our newsletter.");
            } else {
                setStatus("error");
                setMessage("Oops! Something went wrong, please try again.");
            }

            return;
        }
    };

    return { handleSubmit, status, message };
}
