// React
import { useState } from 'react';

// Structure
import OffCanvas from "./OffCanvas";
import OffCanvasProducts from "./OffCanvasProducts";
import SecondaryNavigation from "./SecondaryNavigation";

// Components
import Logo from "./Logo";

export default function Header({ options, fullMenu }) {

    const { activate_login_links, activate_register_links, activate_bookDemo_btn } = options.acf;

    const loginOptions = {
        activate_login_links: options.acf.activate_login_links,
        login_text: options.acf.login_text,
        login_accent_colour: options.acf.login_accent_colour,
        login_links: options.acf.login_links,
    }

    const registerOptions = {
        activate_register_links: options.acf.activate_register_links,
        register_text: options.acf.register_text,
        register_accent_colour: options.acf.register_accent_colour,
        register_link: options.acf.register_link,
    }

    const bookDemoOptions = {
        activate_bookDemo_btn: options.acf.activate_bookDemo_btn,
        bookDemo_button_text: options.acf.bookDemo_button_text,
        bookDemo_link: options.acf.bookDemo_link,
        bookDemo_button_fill: options.acf.bookDemo_button_fill
    }

    const [openProducts, setOpenProducts] = useState(false);
    const [open, setOpen] = useState(false);

    const handleToggle = () => {
        if(open){
            setOpen(false);
        } else {
            setOpen(true);
        }
    }

    const closeToggle = () => {
        setOpen(false);
        setOpenProducts(false);
    }

    const handleToggleProducts = () => {
        if(openProducts){
            setOpenProducts(false);
        } else {
            setOpenProducts(true);
        }
    }

    const closeToggleProducts = () => {
        setOpenProducts(false);
        setOpen(false);
    }

    return (
        <header className="bg-white sticky top-0 z-10 header shadow-lg">
            <div className="px-6 lg:container flex py-8 justify-between items-center">

                <Logo />

                <OffCanvas toggle={open} handleToggle={closeToggle} handleToggleProducts={handleToggleProducts} openProducts={openProducts} fullMenu={fullMenu}/>

                <OffCanvasProducts toggleProducts={openProducts} handleToggleProducts={closeToggleProducts} fullMenu={fullMenu}/>

                <div className="flex items-center">

                    { loginOptions && registerOptions && bookDemoOptions && (activate_login_links || activate_register_links || activate_bookDemo_btn) &&
                        <SecondaryNavigation login={loginOptions} register={registerOptions} bookDemo={bookDemoOptions}  />
                    }

                    <div onClick={handleToggle} className={`toggle cursor-pointer ml-5 lg:hidden ${open ? 'open' : ''}`}>
                        <span></span><span></span><span></span><span></span>
                    </div>

                </div>
            </div>
        </header>
    );
}