// React
import { Navigate, Routes, Route } from "react-router-dom";
import useFetch from "./hooks/useFetch";

// Structure
import Layout from "./components/structure/Layout";

// Templates
import Page from "./components/routes/Page";
import Posts from "./components/routes/Posts";
import PostSingle from "./components/routes/PostSingle";
import Faqs from "./components/routes/Faqs";
import Contact from "./components/routes/Contact";
import For from "./components/routes/For";
import NotFound from "./components/routes/NotFound";
import VerifyRedirect from "./components/routes/VerifyRedirect";

//Font Awesome library
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fat } from '@fortawesome/pro-thin-svg-icons';
library.add(fas, far, fat);

export default function App() {

    const {data: pages, error, loading} = useFetch(`${process.env.REACT_APP_API_URL}pages?per_page=100&acf_format=standard`);
    const {data: options, loading: optionsLoading } = useFetch(`${process.env.REACT_APP_ACF_API_URL}options`);
    const {data: fullMenu, loading: fullMenuLoading} = useFetch(`${process.env.REACT_APP_MENU_API_URL}`)

    const getComponent = (template) => {

        const components = {
            'faqs.php'      : Faqs,
            'contact.php'   : Contact,
            'for-pages.php' : For,
            'news.php'      : Posts
        }

        return ( template !== 'default' && template ) ? components[template] : Page ;
    }

    return (
        <Routes>
            {/*Redirects*/}
            <Route path="user/benefits" element={ <Navigate to="/member-benefits" replace /> } />
            <Route path="user/company_application" element={ <Navigate to="/" replace /> } />
            <Route path="user/company_edit_login" element={ <Navigate to="/" replace /> } />
            <Route path="user/companyDetailslogin" element={ <Navigate to="/" replace /> } />
            <Route path="user/companylogin" element={ <Navigate to="/" replace /> } />
            <Route path="user/companySupplierslogin" element={ <Navigate to="/companies/my-recognised-suppliers-mypqq" replace /> } />
            <Route path="user/faq" element={ <Navigate to="/faqs" replace /> } />
            <Route path="user/faq/general" element={ <Navigate to="/faqs" replace /> } />
            <Route path="user/login" element={ <Navigate to="/" replace /> } />
            <Route path="user/news/" element={ <Navigate to="/news" replace /> } />
            <Route path="user/page/About/33" element={ <Navigate to="/about" replace /> } />
            <Route path="user/page/about-the-scheme/33" element={ <Navigate to="/about" replace /> } />
            <Route path="user/page/Amazing-vehicle-lease-deals-with-cashback-offer-for-Reset-Members/53/178" element={ <Navigate to="/member-benefits/vehicle-lease-deals-from-vanarama" replace /> } />
            <Route path="user/page/Another-NHS-Trust-signs-up-to-Reset/42/153" element={ <Navigate to="/news/nhs-trust-signs-up-to-reset" replace /> } />
            <Route path="user/page/Benefits-for-Reset-Company-Members/53/145" element={ <Navigate to="/member-benefits" replace /> } />
            <Route path="user/page/Benefits-for-Reset-Company-Members/53/146" element={ <Navigate to="/member-benefits/employment-law-and-health-and-safety-support" replace /> } />
            <Route path="user/page/Branded-Workwear-and-Personal-Protection-Equipment/53/205" element={ <Navigate to="/news/partnership-with-leading-workwear-provider-essential-workwear" replace /> } />
            <Route path="user/page/Call-handling-and-web-live-chat-support-service-from-market-leaders-Moneypenny/53/181" element={ <Navigate to="/member-benefits/customer-service-from-moneypenny" replace /> } />
            <Route path="user/page/Company%20Branded%20Cards/60" element={ <Navigate to="/companies/company-branded-cards" replace /> } />
            <Route path="user/page/Company-Branded-Cards/60" element={ <Navigate to="/companies/company-branded-cards" replace /> } />
            <Route path="user/page/Company-Branded-Cards-for-Companies/60/93" element={ <Navigate to="/companies/company-branded-cards" replace /> } />
            <Route path="user/page/Company-Rates/36/27" element={ <Navigate to="/companies" replace /> } />
            <Route path="user/page/contact/38" element={ <Navigate to="/contact" replace /> } />
            <Route path="user/page/Current-Vacancies/33/86" element={ <Navigate to="/about" replace /> } />
            <Route path="user/page/EU-plans-will-hasten-UK-Covid-passport-says-Yorkshire-tech-boss/42/232" element={ <Navigate to="/news/eu-plans-hasten-uk-covid-passport" replace /> } />
            <Route path="user/page/Fair-Usage-Policy/53/156" element={ <Navigate to="/privacy-policy" replace /> } />
            <Route path="user/page/Fantastic-savings-on-diesel-with-the-Reset-Fuel-Card/53/161" element={ <Navigate to="/member-benefits/reset-fuel-card-with-uk-fuels" replace /> } />
            <Route path="user/page/For%20Companies/36" element={ <Navigate to="/companies" replace /> } />
            <Route path="user/page/For%20Individuals/35" element={ <Navigate to="/individuals" replace /> } />
            <Route path="user/page/For%20Verifiers/34" element={ <Navigate to="/verifiers" replace /> } />
            <Route path="user/page/For-Companies/36" element={ <Navigate to="/companies" replace /> } />
            <Route path="user/page/Get-Connected---Stay-Connected/42/155" element={ <Navigate to="/news/get-connected-stay-connected" replace /> } />
            <Route path="user/page/help/41" element={ <Navigate to="/contact" replace /> } />
            <Route path="user/page/How-to-Register-with-Reset/35/21" element={ <Navigate to="/companies" replace /> } />
            <Route path="user/page/information-for-companies/36" element={ <Navigate to="/companies" replace /> } />
            <Route path="user/page/information-for-individuals/35" element={ <Navigate to="/individuals" replace /> } />
            <Route path="user/page/information-for-verifiers/34" element={ <Navigate to="/verifiers" replace /> } />
            <Route path="user/page/Introducing-Time-off-Manager---manage-employees-time-off-efficiently/53/193" element={ <Navigate to="/member-benefits/time-off-manager" replace /> } />
            <Route path="user/page/Lost-and-stolen-cards/35/22" element={ <Navigate to="/contact" replace /> } />
            <Route path="user/page/My-Reset-Company-Profile/36/55" element={ <Navigate to="/companies" replace /> } />
            <Route path="user/page/news/42" element={ <Navigate to="/news" replace /> } />
            <Route path="user/page/news/42/142/" element={ <Navigate to="/news/the-christie-nhs-foundation-trust" replace /> } />
            <Route path="user/page/news/42/143/" element={ <Navigate to="/news/repair-maintenance-company-fined-after-worker-falls" replace /> } />
            <Route path="user/page/news/42/144/" element={ <Navigate to="/news/we-are-off" replace /> } />
            <Route path="user/page/news/42/149/" element={ <Navigate to="/news/reset-launch-benefits-for-members" replace /> } />
            <Route path="user/page/news/42/152/" element={ <Navigate to="/news/supporting-members-compliancy" replace /> } />
            <Route path="user/page/news/42/153/" element={ <Navigate to="/news/nhs-trust-signs-up-to-reset" replace /> } />
            <Route path="user/page/news/42/154/" element={ <Navigate to="/news/will-going-digital-be-taxing" replace /> } />
            <Route path="user/page/news/42/155/" element={ <Navigate to="/news/get-connected-stay-connected" replace /> } />
            <Route path="user/page/news/42/162/" element={ <Navigate to="/news/membership-package-fuel-card-service" replace /> } />
            <Route path="user/page/news/42/169/" element={ <Navigate to="/news/member-benefits-discounts-aa-breakdown-cover" replace /> } />
            <Route path="user/page/news/42/188/" element={ <Navigate to="/news/partnership-with-vanarama-and-member-benefits" replace /> } />
            <Route path="user/page/news/42/206/" element={ <Navigate to="/news/partnership-with-leading-workwear-provider-essential-workwear" replace /> } />
            <Route path="user/page/news/42/231/" element={ <Navigate to="/news/covid-passport-software" replace /> } />
            <Route path="user/page/news/42/232/" element={ <Navigate to="/news/eu-plans-hasten-uk-covid-passport" replace /> } />
            <Route path="user/page/news/42/273/" element={ <Navigate to="/news/tool-protection-solution-membership-benefit-package" replace /> } />
            <Route path="user/page/Protect-your-tools-with-Tool-Watch/53/272" element={ <Navigate to="/news/tool-protection-solution-membership-benefit-package" replace /> } />
            <Route path="user/page/Register-now-with-Reset/36/26" element={ <Navigate to="/companies" replace /> } />
            <Route path="user/page/Reset%20Access/49" element={ <Navigate to="/verifiers/reset-access/" replace /> } />
            <Route path="user/page/Reset%20Company/48" element={ <Navigate to="/companies" replace /> } />
            <Route path="user/page/Reset%20Individual/47" element={ <Navigate to="/individuals" replace /> } />
            <Route path="user/page/Reset%20Member%20Company%20Directory/59" element={ <Navigate to="/companies/member-company-directory" replace /> } />
            <Route path="user/page/Reset%20Predict/57" element={ <Navigate to="/companies/reset-predict" replace /> } />
            <Route path="user/page/Reset%20Verifier%20Web%20App/50" element={ <Navigate to="/verifiers/reset-app" replace /> } />
            <Route path="user/page/Reset_in_4_minutes/56" element={ <Navigate to="/verifiers" replace /> } />
            <Route path="user/page/reset-access/49" element={ <Navigate to="/verifiers/reset-access/" replace /> } />
            <Route path="user/page/Reset-Access-for-Companies/49/68" element={ <Navigate to="/companies/reset-access" replace /> } />
            <Route path="user/page/Reset-Access-for-Individuals/49/69" element={ <Navigate to="/individuals/reset-access" replace /> } />
            <Route path="user/page/Reset-Access-for-Verifiers/49/57" element={ <Navigate to="/verifiers/reset-access/" replace /> } />
            <Route path="user/page/Reset-adds-more-Member-Benefits-with-discounts-on-AA-breakdown-cover/42/169" element={ <Navigate to="/news/member-benefits-discounts-aa-breakdown-cover" replace /> } />
            <Route path="user/page/Reset-announce-partnership-with-leading-workwear-provider-Essential-Workwear/42/206" element={ <Navigate to="/news/partnership-with-leading-workwear-provider-essential-workwear" replace /> } />
            <Route path="user/page/Reset-announce-partnership-with-Vanarama-and-unveil-the-first-of-its-Member-Benefits-of-2020-/42/188" element={ <Navigate to="/news/partnership-with-vanarama-and-member-benefits" replace /> } />
            <Route path="user/page/reset-app/50" element={ <Navigate to="/verifiers/reset-app" replace /> } />
            <Route path="user/page/Reset-App-for-Verifiers/53/56" element={ <Navigate to="/verifiers/reset-app" replace /> } />
            <Route path="user/page/reset-company/48" element={ <Navigate to="/companies/companies/reset-company" replace /> } />
            <Route path="user/page/Reset-Company-for-Companies/48/63" element={ <Navigate to="/companies/reset-company" replace /> } />
            <Route path="user/page/Reset-Company-for-Verifiers/48/59" element={ <Navigate to="/verifiers/reset-company" replace /> } />
            <Route path="user/page/reset-individual/47" element={ <Navigate to="/companies/reset-individual" replace /> } />
            <Route path="user/page/Reset-Individual-for-Companies/47/61" element={ <Navigate to="/companies/reset-individual" replace /> } />
            <Route path="user/page/Reset-Individual-for-Individuals/47/62" element={ <Navigate to="/individuals/reset-individual" replace /> } />
            <Route path="user/page/Reset-Individual-for-Verifiers/47/58" element={ <Navigate to="/verifiers/reset-individual" replace /> } />
            <Route path="user/page/Reset-Introduce-Covid-Passport-Software/42/231" element={ <Navigate to="/news/covid-passport-software" replace /> } />
            <Route path="user/page/Reset-Member-Company-Directory/59" element={ <Navigate to="/companies/member-company-directory" replace /> } />
            <Route path="user/page/Reset-Member-Company-Directory-for-Companies/59/95" element={ <Navigate to="/companies/member-company-directory" replace /> } />
            <Route path="user/page/Reset-Member-Company-Directory-for-Companies/59/95" element={ <Navigate to="/companies/reset-my-employees" replace /> } />
            <Route path="user/page/Reset-Member-Company-Directory-for-Verifiers/59/96" element={ <Navigate to="/verifiers/member-company-directory" replace /> } />
            <Route path="user/page/Reset-Predict/57" element={ <Navigate to="/companies/reset-predict" replace /> } />
            <Route path="user/page/Reset-Predict-for-Companies/57/94" element={ <Navigate to="/companies/reset-predict" replace /> } />
            <Route path="user/page/Reset-strengthens-its-membership-package-with-fuel-card-service-/42/162" element={ <Navigate to="/member-benefits/reset-fuel-card-with-uk-fuels" replace /> } />
            <Route path="user/page/Reset-Virtual-Card-Is-Now-A-Reality/42/127" element={ <Navigate to="/news" replace /> } />
            <Route path="user/page/Reset-Web-App-for-Verifiers/50/56" element={ <Navigate to="/verifiers/reset-app" replace /> } />
            <Route path="user/page/terms-and-conditions/37" element={ <Navigate to="/privacy-policy" replace /> } />
            <Route path="user/page/Tool-Protection-Solution-introduced-to-the-Reset-Membership-Benefit-Package/42/273" element={ <Navigate to="/news/tool-protection-solution-membership-benefit-package" replace /> } />
            <Route path="user/page/Transform-the-way-you-conduct-site-inductions/53/271" element={ <Navigate to="/verifiers/reset-si-mobile" replace /> } />
            <Route path="user/page/Up-to-75%-off-AA-Business-Breakdown-Cover-for-Reset-Members/53/168" element={ <Navigate to="/member-benefits/aa-business-breakdown-cover" replace /> } />
            <Route path="user/page/verify-now/51" element={ <Navigate to="/" replace /> } />
            <Route path="user/page/Will-going-digital-be-taxing-for-you-/42/154" element={ <Navigate to="/news/will-going-digital-be-taxing" replace /> } />
            <Route path="user/resetchallenge" element={ <Navigate to="/" replace /> } />
            <Route path="user/verifiersignup" element={ <Navigate to="/verifiers" replace /> } />
            <Route path="verify" element={ <VerifyRedirect /> } />

            <Route path="/*" element={
                    <Layout
                        error={error}
                        loading={loading}
                        pages={pages}
                        oLoading={optionsLoading}
                        options={options}
                        fullMenuLoading={fullMenuLoading}
                        fullMenu={fullMenu}
                    />}>

                {options && pages && pages.map(page => {

                    let Template = getComponent(page.template)

                    let parentSlug = [];

                    if(page.parent){
                        const parentt = pages.filter((pagee) => pagee.id === page.parent)
                        parentSlug = parentt.map(test=>test.slug)
                    }

                    let path = page.slug !== 'home' ? `${page.parent ? '/' + parentSlug && parentSlug[0] + '/' : '' }${page.slug}/` : '';

                    return (

                        <Route
                            key={page.id}
                            path={path}
                            element={<Template page={page} options={options} pages={pages} />}
                        />

                    )
                })}

                <Route path="news/:slug" element={<PostSingle />} />

                {!loading && !error && pages && <Route path="*"  element={ <NotFound/> } /> }

            </Route>
        </Routes>
    );

}
