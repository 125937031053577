//Components
import Button from "../Button";

export default function CardContent(props) {
    const {
        id,
        page_accent_colour,
        primary_page_title,
        for_card_sub_title,
        for_card_text,
        for_card_button,
        className
    } = props;

    return (
        <div className={className} key={id}>
            <div
                className={`w-12 h-0.5 bg-${page_accent_colour && page_accent_colour} } block mb-2`}></div>
            {primary_page_title &&
                <div className={"mb-4 text-3xl font-bold"}>{primary_page_title}</div>}
            {for_card_sub_title &&
                <h5 className={""}>{for_card_sub_title}</h5>}
            {for_card_text &&
                <div className={"text-sm lg:text-base mb-4"} dangerouslySetInnerHTML={{__html: for_card_text}} />}
            {for_card_button &&
                <Button
                    type={"for"}
                    url={for_card_button.url}
                    accent={page_accent_colour}
                    title={for_card_button.title}
                    iconName={"arrow-right-line"}
                    iconSize={"xl"}
                    target={""}
                />
            }
        </div>
    );
}
