//Components
import Button from "../Button";

//Hooks and helpers
import {decode} from "html-entities";

//Font Awesome Library
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const PrimaryContent = (props) => {

    const {
        title,
        content,
        accent,
        activate_primary_icon,
        primary_icon,
        button,
        button2
    } = props


    return (
    <>
        <section className="py-8">
            <div className={`container grid xl:grid-cols-3 gap-8 lg:gap-12`}>

                <div>
                    {title && <h2 className={`before:content-[''] before:w-12 before:h-0.5 before:bg-${accent} before:block before:mb-3`}>{ decode(title) }</h2> }
                    <div className={`grid space-y-4 xl:space-y-6`}>

                        {activate_primary_icon && primary_icon && <p className={`text-${accent} ml-4 text-6xl md:text-9xl before:content-[''] before:w-12 before:h-0.5 before:bg-${accent} before:block before:mb-3`}><FontAwesomeIcon icon={primary_icon} /></p>}

                        { button &&
                            <Button
                                type={"PScontent"}
                                url={button.url}
                                accent={accent}
                                title={button.title}
                                iconName={"arrow-right-line"}
                                iconSize={"xl"}
                                target={button.target}
                            />
                        }

                        { button2 &&
                            <Button
                                type={"PScontent"}
                                url={button2.url}
                                accent={accent}
                                title={button2.title}
                                iconName={"arrow-right-line"}
                                iconSize={"xl"}
                                target={button2.target}
                            />
                        }

                    </div>
                </div>

                <div className="xl:col-span-2  lg:gap-12" dangerouslySetInnerHTML={{ __html: content }} />

            </div>
        </section>
    </>
    )

}

export default PrimaryContent;
