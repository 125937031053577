//Components
import Slider from "react-slick";
import Button from "../Button"

const BannerSlider = ({ accent, banner }) => {

    const { page_banner_slider_items } = banner;

    var settings = {
        dots: true,
        infinite: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 6000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <>
            { page_banner_slider_items && page_banner_slider_items.length > 0 && (
            <Slider className="slider--banner" {...settings} >
                { page_banner_slider_items.map( (page_banner_slider_item, index) =>
                    <section key={index}>
                        <div
                            className={`flex items-end ${page_banner_slider_item.page_banner_image ? 'min-h-128 sm:min-h-160 bg-center bg-cover bg-no-repeat' : 'bg-grey-200 min-h-96'}`}
                            style={ page_banner_slider_item.page_banner_image ? {backgroundImage: `url(${page_banner_slider_item.page_banner_image})`} : {}}
                        >
                            <div className="w-screen lg:container">
                                <div className={`w-full lg:w-1/2 xl:w-2/5 p-8 text-white bg-${page_banner_slider_item.page_banner_accent ? page_banner_slider_item.page_banner_accent : accent}/75`}>

                                    { page_banner_slider_item.page_banner_title && <h1 className="text-4xl">{page_banner_slider_item.page_banner_title}</h1> }

                                    { page_banner_slider_item.page_banner_text && <p className="mb-8">{page_banner_slider_item.page_banner_text}</p> }

                                    { page_banner_slider_item.page_banner_button &&
                                        <Button
                                            type={`banner`}
                                            url={page_banner_slider_item.page_banner_button.url}
                                            iconName={"arrow-right-line"}
                                            iconSize={"xl"}
                                            title={page_banner_slider_item.page_banner_button.title}
                                            target={page_banner_slider_item.page_banner_button.target}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                )}
            </Slider>
            )}
        </>
    );
}

export default BannerSlider;
