//Components
import Button from "../Button";
import CardWrapper from "../card/CardWrapper";
import Card from "../card/Card";

const ContactSection = ({backgroundColour, options}) => {

    const {
        section_contact_sub_title,
        section_contact_title,
        section_contact_text,
        section_contact_button,
        section_contact_image,
        section_contact_cards: cards,
    } = options.acf;

    return (
        <section className={`${backgroundColour && `bg-${backgroundColour}`} py-20`}>
            <div className="container grid grid-cols-1 lg:grid-cols-3 gap-8">
                <div>
                    { section_contact_sub_title && <p className="mb-4 text-xl font-bold before:content-[''] before:w-12 before:h-0.5 before:bg-orange before:block before:mb-3">{section_contact_sub_title}</p> }

                    { section_contact_title && <h2 className="text-3xl mb-8">{section_contact_title}</h2> }

                    { section_contact_text && <div dangerouslySetInnerHTML={{ __html: section_contact_text }} /> }

                    { section_contact_button &&
                        <Button
                            type={"section"}
                            url={section_contact_button.url}
                            accent={"orange"}
                            margin={`mt-6`}
                            title={section_contact_button.title}
                            iconName={"arrow-right-line"}
                            iconSize={"xl"}
                            target={section_contact_button.target ? section_contact_button.target : '_self'}
                        />
                    }
                </div>

                <div className="col-span-2 grid grid-cols-2 gap-0.5">
                    {cards && cards.map((card, index) =>
                        <CardWrapper className={`col-span-2 lg:col-span-1`}>
                            <Card
                                key={index}
                                phone={card.section_about_card_phone && card.section_about_card_phone}
                                email={card.section_about_card_email && card.section_about_card_email}
                                bgColour={`bg-slate-dark hover:bg-slate-dark/80 transition-all`}
                                type={'contact'}
                                title={card.section_about_card_title && card.section_about_card_title}
                                text={card.section_about_card_text && card.section_about_card_text}
                                accent={card.section_about_card_accent && card.section_about_card_accent}
                                icon={card.section_about_card_icon && card.section_about_card_icon}
                            />
                        </CardWrapper>
                    )}
                </div>
            </div>
        </section>
    );
}

export default ContactSection;
