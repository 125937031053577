//React
import { Link } from "react-router-dom";

//Components
import Icon from "../Icon";
import Button from "../Button";

//Hooks and helpers
import {decode} from "html-entities";

//Font Awesome Library
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Card = (props) => {

    const {
        type, accent, bgColour,
        subtitle, title, text,
        url, icon, email, phone,
        handleClick, id, benefit0,
        benefit1, benefit2, benefit3,
        isBright, className, image,
        benefitSelector
    } = props;

    const cardStyle = `${bgColour ? `${bgColour} text-white` : `bg-white hover:bg-grey-100 transition-all`}`;

    if (type === 'about') {
        return (
            <div className={`${cardStyle} p-6 pb-2 relative `}>
                <div className={`w-12 h-0.5 bg-${accent} block mb-3`}></div>
                {subtitle && <p>{subtitle}</p>}
                {title && <h5>{title}</h5>}
                {text && <p className="text-sm pb-4">{text}</p>}
                {url && url.startsWith('https://') ? <a href={url} target="_blank" rel="noreferrer" className={`absolute top-0 left-0 w-full h-full`}><p></p></a> : <Link to={url} className={`absolute top-0 left-0 w-full h-full`}></Link>}
                <div>{icon && <FontAwesomeIcon icon={icon} className={"flex flex-col h-6 py-4"}/>}</div>
            </div>
        )
    }

    if (type === 'contact') {
        return (
            <div className={`${cardStyle} p-6 relative flex flex-col justify-between`}>
                <div className={`mb-2`}>
                    <div className={`w-12 h-0.5 bg-${accent} block mb-3`}></div>
                    {subtitle && <p>{subtitle}</p>}
                    {title && <h5>{title}</h5>}
                    {text && <p className="text-lg">{text}</p>}
                </div>

                <div>{icon && <FontAwesomeIcon icon={icon} className={"flex flex-col h-6 py-4"}/>}</div>

                {email && <a href={`mailto:${email}`} className={`absolute top-0 left-0 w-full h-full`}> </a>}
                {phone &&
                    <a href={`tel:${phone.replace(/ /g, '')}`} className={`absolute top-0 left-0 w-full h-full`}> </a>}
                {url && url.startsWith('https://') ? <a href={url} target="_blank" rel="noreferrer" className={`absolute top-0 left-0 w-full h-full`}><p></p></a> : <Link to={url} className={`absolute top-0 left-0 w-full h-full`}></Link>}
            </div>
        )
    }

    if (type === 'compliance') {
        return (
            <div className={`${cardStyle} p-6 relative flex flex-col justify-between`}>
                <div className={`mb-8`}>
                    <div className={`w-12 h-0.5 bg-${accent} block mb-3`}></div>
                    {subtitle && <p>{subtitle}</p>}
                    {title && <h5>{title}</h5>}
                    {text && <p className="text-lg">{text}</p>}
                </div>

                {icon && <Icon name={icon} size="3xl"/>}
                {email && <a href={`mailto:${email}`} className={`absolute top-0 left-0 w-full h-full`}> </a>}
                {phone && <a href={`tel:${phone.replace(/ /g, '')}`} className={`absolute top-0 left-0 w-full h-full`}> </a>}
                {url && url.startsWith('https://') ? <a href={url} target="_blank" rel="noreferrer" className={`absolute top-0 left-0 w-full h-full`}><p></p></a> : <Link to={url} className={`absolute top-0 left-0 w-full h-full`}></Link>}
            </div>
        )
    }

    if (type === 'solitary') {
        return (
            <div className={`${cardStyle} p-6 ${className}`}>
                <div className={`w-12 h-0.5 bg-${accent} block`}></div>
                {subtitle && <p>{subtitle}</p>}
                {title && <h5>{title}</h5>}
                {text && <p className="text-sm">{text}</p>}
            </div>
        )
    }

    if (type === 'for') {
        return (
            <div onClick={() => handleClick(id)} className={`${cardStyle} p-4 pt-8  md:p-6 md:pt-10  max-h-56 cursor-pointer`}>
                <FontAwesomeIcon icon={icon} className={"h-6 mb-2"}/>
                <div className={`w-12 h-0.5 bg-${accent} ${isBright ? 'brightness-150': '' } block mb-2`}></div>

                <div className={" flex justify-between"}>
                    {title && <p className={"w-5/6 text-lg font-semibold"}>{title}</p> }
                    <Icon name="arrow-down-line" size="2xl" color={accent} className={isBright ? 'brightness-150': '' }/>
                </div>

            </div>
        )
    }

    if (type === 'benefit') {
        return (
                <div className={` p-6 relative bg-grey-100 text-black`}>

                    <div className={`w-12 h-0.5 bg-${accent} block mb-4`}></div>

                    {benefitSelector === "icon" && icon && <FontAwesomeIcon icon={icon} className={`h-12 w-12 mb-2 ml-2 text-${accent}`}/>}

                    {benefitSelector === "image" && image && <img alt="" className=" bg-contain bg-no-repeat  row-auto h-10 w-auto mb-4"
                         src={`${image}`}></img> }

                    {subtitle && <p>{subtitle}</p>}

                    {title && <h5>{title}</h5>}

                    <ul className={`px-4 `}>
                        {benefit0 && <li className="text-sm list-outside">{benefit0}</li>}
                        {benefit1 && <li className="text-sm list-outside">{benefit1}</li>}
                        {benefit2 && <li className="text-sm list-outside">{benefit2}</li>}
                        {benefit3 && <li className="text-sm list-outside">{benefit3}</li>}
                    </ul>

                    {url && url.startsWith('https://') ? <a href={url} target="_blank" rel="noreferrer" className={`absolute top-0 left-0 w-full h-full`}><p></p></a> : <Link to={url} className={`absolute top-0 left-0 w-full h-full`}></Link>}
                </div>
        )
    }

    if(type === 'journal'){
        return (
            <div className={`${className && className} flex flex-col bg-grey-200`}>
                {image &&
                    <div className="card__media h-56 md:h-44">
                        <img className={"object-cover w-full h-full"} src={image} alt={decode(title)} />
                    </div>
                }
                <div className="card__content p-6 pb-3 flex-1">
                    <div className={`w-12 h-0.5 bg-${accent} block mb-3`}></div>
                    { title && <p className="text-lg font-semibold">{decode(title)}</p> }
                    { text && <div dangerouslySetInnerHTML={{ __html: text }} /> }
                </div>

                <footer className="justify-end p-6 pt-3">
                    {url.url && url.title &&
                        <Button
                            type={"journal"}
                            url={url.url}
                            accent={accent}
                            title={url.title}
                            iconName={"arrow-right-line"}
                            iconSize={"xl"}
                            target={url.target}
                        />
                    }
                </footer>
            </div>
        )
    }
}

export default Card;
