//Components
import Button from "../Button";

//Hooks and Helpers
import useFetch from "../../hooks/useFetch";

const PartnerBenefitsSection = ({options}) => {

    const {data: partners} = useFetch(`${process.env.REACT_APP_API_URL}partners/?acf_format=standard`);

    const {
        section_partner_benefits_sub_title,
        section_partner_benefits_title,
        section_partner_benefits_text,
        section_partner_benefits_button,
        section_partner_benefits_background_image
    } = options.acf;

    return (
        <>
            <section className="bg-center bg-cover bg-no-repeat pt-56" style={{backgroundImage: `url(${section_partner_benefits_background_image ? section_partner_benefits_background_image : 'https://dummyimage.com/1900x600'})`}}>

                <div className="container h-full flex items-end justify-start">

                    <div className="grid lg:grid-cols-2 items-start gap-6 bg-white py-10 px-8 xl:w-[65%]">
                        <header>
                            { section_partner_benefits_sub_title && <p className="mb-4 text-xl font-bold before:content-[''] before:w-12 before:h-0.5 before:bg-orange before:block before:mb-3">{section_partner_benefits_sub_title}</p> }

                            { section_partner_benefits_title && <h2 className="text-3xl mb-8">{section_partner_benefits_title}</h2> }

                            { section_partner_benefits_text && <div dangerouslySetInnerHTML={{ __html: section_partner_benefits_text }} /> }

                            { section_partner_benefits_button &&
                                <Button
                                    type={"section"}
                                    url={section_partner_benefits_button.url}
                                    margin={`mt-4`}
                                    accent={"green"}
                                    title={section_partner_benefits_button.title}
                                    iconName={"arrow-right-line"}
                                    iconSize={"xl"}
                                    target={section_partner_benefits_button.target ? section_partner_benefits_button.target : '_self'}
                                />
                            }
                        </header>

                        {partners && partners.length > 0 && (
                            <div className="flex flex-wrap gap-6 justify-center lg:justify-start">
                                { partners.map( partner =>
                                    <div key={partner.id} className="self-center">
                                        <img src={partner.acf.partner_logo} alt={`${partner.title.rendered} Logo`} className="mx-auto max-h-10" />
                                    </div>
                                ) }
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}

export default PartnerBenefitsSection;
