import React from 'react';
import ViewSDKClient from "./ViewSDKClient.js"

const Viewer = ({url}) => {

    const loadPDF = () => {

        const viewSDKClient = new ViewSDKClient();

        viewSDKClient.ready().then(() => {
            viewSDKClient.previewFile("pdf-div",
            {
                showAnnotationTools: false,
                showLeftHandPanel: false,
                showPageControls: false,
                showDownloadPDF: false,
                showPrintPDF: false
            },
            url
        );
        });
    };


    return (
        <div>
            <div
                id="pdf-div"
                className="full-window-div"
                onDocumentLoad={loadPDF()}
            />
        </div>
    );

};

export default Viewer;