//React
import {Fragment, useState} from "react";

//Components
import Card from "../card/Card";
import CardContent from "../card/CardContent";
import Button from "../Button";

const IndividualsSection = ({backgroundColour, options, pages}) => {

    const [tab, setTab] = useState()

    const parentPages = pages && pages.sort((a, b) => a.menu_order - b.menu_order)
        .filter(page => (page.parent === 0) && (page.template === "for-pages.php"))

    const childPages = pages && pages.filter(page => (page.parent !== 0) && (page.template === "for-pages.php"))

    const individualsChildren = childPages.filter(page => (page.parent === parentPages[1].id))


    function handleClick(index) {
        if(tab === index){
            setTab(null);
        } else {
            setTab(index);

        }
    }

    const {
        section_individual_product_sub_title,
        section_individual_product_title,
        section_individual_product_text,
        section_individual_product_button,
        section_individual_feature_image
    } = options.acf;

    return (
        <section className={`${backgroundColour && `bg-${backgroundColour}`} py-20`}>
            <div className="container grid grid-cols-1 lg:grid-cols-3 gap-8">
                <div className={"w-4/5"}>
                    {section_individual_product_sub_title &&
                        <p className="mb-4 text-xl font-bold before:content-[''] before:w-12 before:h-0.5 before:bg-purple before:block before:mb-3">{section_individual_product_sub_title}</p>}

                    {section_individual_product_title &&
                        <h2 className="text-3xl mb-8">{section_individual_product_title}</h2>}

                    {section_individual_product_text &&
                        <div className="mb-6 text-sm"
                             dangerouslySetInnerHTML={{__html: section_individual_product_text}}/>}

                    {section_individual_product_button &&
                        <Button
                            type={"section"}
                            url={section_individual_product_button.url}
                            accent={"purple"}
                            title={section_individual_product_button.title}
                            iconName={"arrow-right-line"}
                            iconSize={"xl"}
                            target={section_individual_product_button.target ? section_individual_product_button.target : '_self'}
                        />
                    }
                </div>

                    <div className={`grid grid-cols-1 lg:grid-cols-3 col-span-2 gap-0.5`}>
                        <div className="card-section grid gap-0.5">
                            {individualsChildren.sort((a, b) => a.menu_order - b.menu_order).map((page, index) =>
                                <Fragment key={index}>
                                    {page.acf.for_card_activate  &&
                                        <Card className={``}
                                              isBright
                                              key={index}
                                              handleClick={handleClick}
                                              url={'/'}
                                              bgColour={"bg-slate hover:bg-slate/80 transition-all"}
                                              type={'for'}
                                              svgUrl={page.acf.for_card_icon}
                                              title={page.acf.for_card_title && page.acf.for_card_title}
                                              accent={page.acf.page_accent_colour && page.acf.page_accent_colour}
                                              icon={page.acf.for_card_icon_name}
                                              id={index}
                                        />}
                                </Fragment>
                            )}

                            <Card
                                className={"hidden lg:block"}
                                type={'solitary'}
                                title={"0.6 million injuries"}
                                subtitle={"For Individuals"}
                                accent={"purple"}
                                id={"individualSolitaryCard"}
                                text={"to workers in 2022/23 (Source: HSE National Statistics 2023)"}

                            />
                        </div>

                        <div className="card-content bg-no-repeat bg-center bg-cover col-span-2 lg:aspect-auto" style={{backgroundImage: `url(${section_individual_feature_image.url})`}}>
                            {individualsChildren.sort((a, b) => a.menu_order - b.menu_order).map((page, index) =>
                                tab === index &&
                                    <CardContent
                                        className={" bg-white p-12 h-full "}
                                        key={index}
                                        id={index}
                                        page_accent_colour={page.acf.page_accent_colour}
                                        primary_page_title={page.acf.primary_page_title}
                                        for_card_sub_title={page.acf.for_card_sub_title}
                                        for_card_text={page.acf.for_card_text}
                                        for_card_button={page.acf.for_card_button}
                                        page_banner_button={page.acf.page_banner_button}
                                        contentType={"tab"}
                                    />
                            )}
                        </div>
                    </div>
            </div>
        </section>
    );
}

export default IndividualsSection;
