//Components
import CardWrapper from "./card/CardWrapper";
import Card from "./card/Card";

const ContactCards = ({options}) => {

    const {
        general_phone_number,
        general_email_address,
        general_website_url,
        general_address,
        support_phone_number,
        support_email_address,
        lost_stolen_phone_number,
        lost_stolen_emergency_phone_number,
        accounts_phone_number,
        accounts_email_address
    } = options.acf;

    return (
        <section className="py-20">
            <div className="container grid lg:grid-cols-3 gap-8 mb-16 lg:mb-24">
                <div>
                    <h2 className="text-3xl before:content-[''] before:w-12 before:h-0.5 before:bg-orange before:block before:mb-3">
                        General Enquiries
                    </h2>
                </div>
                <div className="lg:col-span-2">
                    <CardWrapper className={`sm:grid-cols-2 xl:grid-cols-3 md:auto-rows-fr`}>
                        {general_phone_number &&
                            <Card
                                phone={general_phone_number}
                                bgColour={`bg-slate-dark hover:bg-slate-dark/80 transition-all`}
                                type={'contact'}
                                title={'Telephone'}
                                text={general_phone_number}
                                accent={'orange'}
                                icon={'fa-thin fa-phone'}
                            />
                        }
                        {general_email_address &&
                            <Card
                                email={general_email_address}
                                bgColour={`bg-slate-dark hover:bg-slate-dark/80 transition-all`}
                                type={'contact'}
                                title={'Email'}
                                text={general_email_address}
                                accent={'orange'}
                                icon={'fa-thin fa-envelope'}
                            />
                        }
                        {general_website_url &&
                            <Card
                                url={"/"}
                                bgColour={`bg-slate-dark hover:bg-slate-dark/80 transition-all`}
                                type={'contact'}
                                title={'Website'}
                                text={general_website_url}
                                accent={'orange'}
                                icon={'fa-thin fa-browser'}
                            />
                        }
                        {general_address &&
                            <Card
                                bgColour={`bg-slate-dark hover:bg-slate-dark/80 transition-all`}
                                type={'contact'}
                                title={'Mail'}
                                text={general_address}
                                accent={'orange'}
                                icon={'fa-thin fa-envelope-open'}
                            />
                        }
                    </CardWrapper>
                </div>
            </div>

            <div className="container grid lg:grid-cols-3 gap-8 mb-16 lg:mb-24">
                <div>
                    <h2 className="text-3xl before:content-[''] before:w-12 before:h-0.5 before:bg-orange before:block before:mb-3">Reset
                        Support Team</h2>
                    <p className="text-xl font-bold">(9am - 5pm Mon - Fri)</p>
                </div>
                <div className="lg:col-span-2">
                    <CardWrapper className={`sm:grid-cols-2 xl:grid-cols-3 md:auto-rows-fr`}>
                        {support_phone_number &&
                            <Card
                                phone={support_phone_number}
                                bgColour={"bg-slate-dark hover:bg-slate-dark/80 transition-all"}
                                type={"contact"}
                                title={"Telephone"}
                                text={support_phone_number}
                                accent={'orange'}
                                icon={"fa-thin fa-phone"}
                            />
                        }

                        {support_email_address &&
                            <Card
                                email={support_email_address}
                                bgColour={`bg-slate-dark hover:bg-slate-dark/80 transition-all`}
                                type={'contact'}
                                title={'Email'}
                                text={support_email_address}
                                accent={'orange'}
                                icon={'fa-thin fa-envelope'}
                            />
                        }

                    </CardWrapper>
                </div>
            </div>

            <div className="container grid lg:grid-cols-3 gap-8 mb-16 lg:mb-24">
                <div>
                    <h2 className="text-3xl before:content-[''] before:w-12 before:h-0.5 before:bg-orange before:block before:mb-3">Lost
                        or Stolen Cards</h2>
                </div>
                <div className="lg:col-span-2">
                    <CardWrapper className={`sm:grid-cols-2 xl:grid-cols-3 md:auto-rows-fr`}>
                        {lost_stolen_phone_number &&
                            <Card
                                phone={lost_stolen_phone_number}
                                bgColour={"bg-slate-dark hover:bg-slate-dark/80 transition-all"}
                                type={"contact"}
                                title={"Office Hours Telephone"}
                                text={lost_stolen_phone_number}
                                accent={'orange'}
                                icon={"fa-thin fa-phone"}
                            />
                        }
                        {lost_stolen_emergency_phone_number &&
                            <Card
                                phone={lost_stolen_emergency_phone_number}
                                bgColour={"bg-slate-dark hover:bg-slate-dark/80 transition-all"}
                                type={"contact"}
                                title={"Outside Office Hours Telephone"}
                                text={lost_stolen_emergency_phone_number}
                                accent={'orange'}
                                icon={"fa-thin fa-phone"}
                            />
                        }
                        {support_email_address &&
                            <Card
                                email={support_email_address}
                                bgColour={`bg-slate-dark hover:bg-slate-dark/80 transition-all`}
                                type={'contact'}
                                title={'Email'}
                                text={support_email_address}
                                accent={'orange'}
                                icon={'fa-thin fa-envelope'}
                            />
                        }
                    </CardWrapper>
                </div>
            </div>

            <div className="container grid lg:grid-cols-3 gap-8">
                <div>
                    <h2 className="text-3xl before:content-[''] before:w-12 before:h-0.5 before:bg-orange before:block before:mb-3">Reset
                        Accounts Team</h2>
                </div>
                <div className="lg:col-span-2">
                    <CardWrapper className={`sm:grid-cols-2 xl:grid-cols-3 md:auto-rows-fr`}>

                        {accounts_phone_number &&
                            <Card
                                phone={accounts_phone_number}
                                bgColour={"bg-slate-dark hover:bg-slate-dark/80 transition-all"}
                                type={"contact"}
                                title={"Telephone"}
                                text={accounts_phone_number}
                                accent={'orange'}
                                icon={"fa-thin fa-phone"}
                            />
                        }

                        {accounts_email_address &&
                            <Card
                                email={accounts_email_address}
                                bgColour={`bg-slate-dark hover:bg-slate-dark/80 transition-all`}
                                type={'contact'}
                                title={'Email'}
                                text={accounts_email_address}
                                accent={'orange'}
                                icon={'fa-thin fa-envelope'}
                            />
                        }

                    </CardWrapper>
                </div>
            </div>
        </section>
    )
}
export default ContactCards;
