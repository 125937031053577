//Components
import Button from "../Button"
import Viewer from "../Viewer/Viewer";

//Hooks and helpers
import { decode } from "html-entities";

const PDFSection = ({data, accent}) => {

    const {
        pdf_section_title,
        pdf_section_content,
        pdf_section_button,
        pdf_section_button_icon
    } = data;

    const PDFURL = data && data.pdf_section_file;

    return (
        <section className="py-20">
            <div className="container grid lg:grid-cols-3 gap-8">
                <div>
                    <h2 className={`mb-8 before:content-[''] before:w-12 before:h-0.5 before:bg-${accent} before:block before:mb-3`}>{decode(pdf_section_title)}</h2>

                    {pdf_section_button &&
                            <Button
                                type={`pdf`}
                                url={pdf_section_button.url}
                                accent={accent}
                                target={pdf_section_button.target ? pdf_section_button.target : '_self'}
                                title={pdf_section_button.title}
                                iconName={pdf_section_button_icon && pdf_section_button_icon}
                                iconSize={`xl`}
                            />
                    }

                </div>

                <div className="col-span-2">

                    <div className={`mb-6`} dangerouslySetInnerHTML={{__html: pdf_section_content}} />
                    <Viewer url={PDFURL}/>

                </div>
            </div>
        </section>
    );
}

export default PDFSection;
