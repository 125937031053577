// React
import { Link } from "react-router-dom";
import { decode } from "html-entities";

// Components
import Icon from "../Icon";

// Hooks & Helpers
import useFetch from "../../hooks/useFetch";
import { replaceUrl } from "../../functions/Helpers";

export default function Footer({options: contacts, error: contactError, fullMenu}) {

    const id = fullMenu.footer_menu

    const {data: menu} = useFetch(process.env.REACT_APP_MENU_API_URL + id);

    return (
        <footer className={`footer`}>
            <div className="bg-slate text-white py-16">
                <div className="px-6 lg:container">

                    <div className="mb-8 lg:mb-12">
                        <h3>Contractor Management Solutions</h3>
                    </div>

                    <div className="lg:grid lg:grid-cols-3 gap-12">
                        { !contactError && contacts &&
                            <div className="mb-10 lg:mb-0">
                                { contacts.acf.footer_about_title && <h4>{contacts.acf.footer_about_title}</h4> }

                                { contacts.acf.footer_about_text &&<p>{contacts.acf.footer_about_text}</p>}

                                { contacts.acf.general_phone_number && contacts.acf.footer_about_phone && <p className="font-semibold"><a href={`tel:${contacts.acf.general_phone_number.replace(/ /g, '')}`}>{contacts.acf.general_phone_number}</a></p> }

                                { contacts.acf.general_email_address && contacts.acf.footer_about_email && <p className="font-semibold"><a href={`mailto:${contacts.acf.general_email_address}`}>{contacts.acf.general_email_address}</a></p> }

                                { contacts.acf.footer_about_button &&
                                    <>
                                        <hr className="border-t border-t-grey-500 my-8" />
                                        <Link to={contacts.acf.footer_about_button.url} className={`flex justify-start items-center`}>
                                            {contacts.acf.footer_about_button.title}
                                            <Icon name="arrow-right-line ml-3" size="xl" />
                                        </Link>
                                    </>
                                }
                            </div>
                        }
                        <nav className="col-span-2">

                            <h4>Quick Links</h4>
                            { contactError && <div>{contactError}</div> }

                            { !contactError && menu && (
                                <ul className="sm:columns-2 gap-12 leading-8">
                                    {menu.map((item, index) => {

                                        const url = replaceUrl(item.url);

                                        return (
                                            <li key={index}>
                                                {(item.classes[0] === 'pdf' || item.target !== '') ?
                                                    <a href={url} className="flex justify-between items-center" target="_blank" rel="noreferrer" >
                                                        {decode(item.title)}
                                                        <Icon name="arrow-right-line ml-3" className="hidden sm:inline-block" size="xl" />
                                                    </a>
                                                :
                                                    <Link to={url} className="flex justify-between items-center">
                                                        {decode(item.title)}
                                                        <Icon name="arrow-right-line ml-3" className="hidden sm:inline-block" size="xl" />
                                                    </Link>
                                                }
                                            </li>
                                        )
                                    })}
                                </ul>
                            )}
                        </nav>
                    </div>

                </div>
            </div>
            <div className="bg-grey-300 py-4 text-grey-700">
                <div className="container flex flex-col lg:flex-row justify-between lg:space-x-4">
                    <div className="mb-1 sm:mb-0 text-xs flex flex-col space-y-0">
                        <div className="h-16 mb-2 flex space-x-4">
                            <img src="https://wpb.rcscard.co.uk/wp-content/uploads/2023/09/ETS4lv0XQAMQt3B.png" alt="ISO9001:2015 certified" className="h-16" />
                            <img src="https://wpb.rcscard.co.uk/wp-content/uploads/2023/09/Cyber-Essentials.png" alt="Cyber Essentials certified" className="h-16" />
                            <img src="https://wpb.rcscard.co.uk/wp-content/uploads/2024/05/ccss.webp" alt="Crown Commercial Services supplier" className="h-16" />
                        </div>

                        <p>Registered in England 6614285.</p>
                        <p>Registered with the Information Commissioner’s Office (ICO) General Data Protection Regulation (GDPR) 2018 - registration no. Z2238936.</p>
                    </div>
                    <p className="mb-1 sm:mb-0 lg:ml-auto shrink-0 lg:my-auto">Reset Compliance Systems Ltd &copy; 2010-{new Date().getFullYear()}</p>
                </div>
            </div>
        </footer>
    );
}
