// Sections
import BannerSection from "../slides/BannerSection";
import BannerSlider from "../slides/BannerSlider";
import PrimaryContent from "../slides/PrimaryContent";

// Global Sections
import AboutSection from "../slides/AboutSection";
import MemberBenefitsSection from "../slides/MemberBenefitsSection";
import HelpSection from "../slides/HelpSection";
import ContactSection from "../slides/ContactSection";
import ClientSection from "../slides/ClientSection";
import VacanciesSection from "../slides/VacanciesSection";
import CompaniesSection from "../slides/CompaniesSection";
import VerifiersSection from "../slides/VerifiersSection";
import IndividualsSection from "../slides/IndividualsSection";
import JournalSection from "../slides/JournalSection";
import SecondaryContent from "../slides/SecondaryContent";
import PartnerBenefitsSection from "../slides/PartnerBenefitsSection";
import ClientSitesSection from "../slides/ClientSiteSection";

//Hooks and Helpers
import useFetch from "../../hooks/useFetch";

//helmet SEO
import Helmet from "react-helmet";

const Page = ({ page, options }) => {

    const { acf, content } = page

    const {
        page_accent_colour,
        page_banner_active,
        page_banner_slider,
        primary_page_title,
        secondary_content,
        activate_primary_icon,
        primary_icon,
        primary_button,
        primary_button_2,
        page_global_sections,
        seo_meta_title,
        seo_meta_description,
        seo_canonical_url,
        seo_schema
    } = acf

    const {data: pages } = useFetch(`${process.env.REACT_APP_API_URL}pages?per_page=100&acf_format=standard`);

    const getSection = (section) => {

        const sections = {
            'about'             : AboutSection,
            'member-benefits'   : MemberBenefitsSection,
            'help-centre'       : HelpSection,
            'contact'           : ContactSection,
            'clients'           : ClientSection,
            'vacancies'         : VacanciesSection,
            'companies'         : CompaniesSection,
            'verifiers'         : VerifiersSection,
            'individuals'       : IndividualsSection,
            'journal'           : JournalSection,
            'partner-benefits'  : PartnerBenefitsSection,
            'client-sites'      : ClientSitesSection
        }

        return (section) ? sections[section] : false ;
    }

    return (
        <>
            <Helmet>
                <title>{seo_meta_title ? seo_meta_title : 'Reset Compliance Systems' }</title>
                {seo_meta_title ? <meta property="og:title" content={seo_meta_title} /> : <meta property="og:title" content="Reset Compliance Systems" /> }
                <meta name="description" property="og:description" content={seo_meta_description ? seo_meta_description : 'Reset Compliance Systems is a competence-verification scheme that saves time and resources in the process of admitting third-party contractors onto any site, ensuring compliance with HSE and CDM obligations.'} />
                <meta name="keywords" content="Reset, rcscard, Reset Compliance Systems, Contractor, Contractor Management, CDM, HSE, Building regulations, Health and Safety, Credentials, Management Solutions, Control of Contractors, Estates Management, Facilities Management" />
                <meta property="robots" content="index, follow" />
                {seo_canonical_url && <link rel="canonical" href={seo_canonical_url} />}
                {seo_schema && <script type="application/ld+json">{seo_schema}</script>}
            </Helmet>

            {(page_banner_active === true && page_banner_slider) && <BannerSlider accent={page_accent_colour} banner={acf} /> }
            {(page_banner_active === true && !page_banner_slider) && <BannerSection accent={page_accent_colour} banner={acf} /> }

            { primary_page_title && content.rendered &&
                <PrimaryContent
                    accent={page_accent_colour && page_accent_colour}
                    title={primary_page_title && primary_page_title}
                    content={content.rendered && content.rendered}
                    activate_primary_icon={activate_primary_icon}
                    primary_icon={primary_icon && primary_icon}
                    button={primary_button && primary_button}
                    button2={primary_button_2 && primary_button_2}/>
            }

            {page.slug === "member-benefits"  && <MemberBenefitsSection data={acf} accent={page_accent_colour} pages={pages}/> }

            {secondary_content && <SecondaryContent data={acf} accent={page_accent_colour}/>}

            { options && pages && page_global_sections && page_global_sections.map(( section, index ) => {

                let Section = getSection(section.page_global_section);

                return (
                    <Section key={index} options={options} backgroundColour={section.page_global_section_background_colour} pages={pages} />
                )
            })}
        </>
    )
}

export default Page;
