//React
import {useState} from "react";

//Hooks and helpers
import {decode} from "html-entities";

const TabbedPageSection = ({data, accent}) => {

    const [tab, setTab] = useState(0)

    function handleClick(index){
        setTab(index);
    }

    const {tab_section_title, tab_sections} = data;

    return (
        <section className="bg-grey-200 py-20">
            <div className="container grid lg:grid-cols-3 gap-8">
                <div className="lg:border-r lg:border-r-grey-300">
                    <h2 className={`mb-8 before:content-[''] before:w-12 before:h-0.5 before:bg-${accent} before:block before:mb-3`}>{decode(tab_section_title)}</h2>

                    <ul className="list-none ">
                        { tab_sections.map((link, index) =>
                            <li className={`cursor-pointer leading-10 mr-4 last:mr-0 lg:mr-0 ${tab === index && `font-bold border-b-2 border-b-${accent} lg:border-b-0 lg:border-r-2 lg:border-r-${accent}`}`}
                                key={index}
                                onClick={()=>handleClick(index)}
                            >
                                {link.tab_title}
                            </li>
                        )}
                    </ul>
                </div>

                <div className="lg:col-span-2">
                    { tab_sections.map((link, index) =>
                        tab === index && <div key={index}>
                            <h3 className="mb-8">{link.tab_title}</h3>
                            <div dangerouslySetInnerHTML={{__html: link.tab_content}} />
                        </div>
                    )}
                </div>

            </div>
        </section>
    );
}

export default TabbedPageSection;
