//Components
import Icon from "./Icon";

export default function Alert({type, msg}) {
    if(type === "error") {
        return (
            <div className="container">
                <div className="bg-white border border-l-red border-grey-200 border-l-2 text-center flex items-center py-2 px-4">
                    <Icon className="mr-3" name="error-warning-line" size="xl" color="red"/>
                    <p>{msg}</p>
                </div>
            </div>
        );
    }

    if(type === "success") {
        return (
            <div className="container">
                <div className="bg-white border border-l-green border-grey-200 border-l-2 text-center flex items-center py-2 px-4">
                    <Icon className="mr-3" name="check-line" size="xl" color="green"/>
                    <p>{msg}</p>
                </div>
            </div>
        );
    }
}
