// React
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom"

// App
import App from './App';

//Components
import ScrollToTop from "./components/ScrollToTop";

// Stylesheets
import './index.scss';

// Remix Icons
import 'remixicon/fonts/remixicon.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
        <ScrollToTop>
            <App/>
        </ScrollToTop>
    </BrowserRouter>
);
