//React
import {useEffect, useState} from "react";

const Navigation = ({ children }) =>{

    const [activeNavItem, setActiveNavItem] = useState("")

    useEffect(() => {
        localStorage.setItem("activeNavItem", JSON.stringify(activeNavItem));
    }, [activeNavItem]);

    const handleClick = (prop) =>{
        setActiveNavItem(prop)
    }

    return (
        <nav>
            <ul className="text-center lg:flex lg:items-center">
                {children && children.map((child, index) => (
                    <li key={index}
                        onClick={() => handleClick(child.key)}
                        identifier={child.key}
                        className="whitespace-nowrap"
                    >
                        {child}
                    </li>
                ))}
            </ul>
        </nav>
    )
}

export default Navigation;
