//Components
import Icon from "../Icon";
import Button from "../Button";

const NotFound = () => {

        return (
            <div className="container py-36 text-center">
                <Icon name="emotion-sad-line" size="8xl" />
                <h1 className="text-7xl mb-2 mt-6 font-regular">404</h1>
                <p className="text-2xl font-thin">Page not found</p>

                <Button
                    type={"not_found"}
                    url={`/`}
                    title={`Go to home page`}
                    iconName={"arrow-right-line"}
                    iconSize={"xl"}
                />
            </div>
        );
};

export default NotFound;
