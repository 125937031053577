// React
import { Outlet } from "react-router-dom";

// Structure
import Header from "./Header"
import Footer from "./Footer";

// Components
import Loading from "../Loading";
import Alert from "../Alert";

const Layout = ({options, loading, error, pages, optionsLoading, fullMenu, fullMenuLoading }) => {

    return (
        <>
            { fullMenu && options && <Header options={options} fullMenu={fullMenu}/> }

            { loading && <Loading color="orange" /> }

            { optionsLoading && <Loading color="orange" /> }

            { fullMenuLoading && <Loading color="orange" />}

            { error && <Alert type="error" msg={error} /> }

            { !fullMenuLoading && !optionsLoading && !loading && !error && <Outlet /> }

            { fullMenu && options && pages && <Footer pages={pages} options={options} fullMenu={fullMenu}/> }
        </>
    );
}

export default Layout;