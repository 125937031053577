// Sections
import BannerSection from "../slides/BannerSection";
import PrimaryContent from "../slides/PrimaryContent";
import ContactCards from "../ContactCards";

// Global Sections
import AboutSection from "../slides/AboutSection";
import MemberBenefitsSection from "../slides/MemberBenefitsSection";
import HelpSection from "../slides/HelpSection";
import ContactSection from "../slides/ContactSection";
import ClientSection from "../slides/ClientSection";
import ClientSitesSection from "../slides/ClientSiteSection";
import VacanciesSection from "../slides/VacanciesSection";
import useFetch from "../../hooks/useFetch";

//helmet SEO
import Helmet from "react-helmet";

const ContactsPage = ({ page, options }) => {

    const {data: pages } = useFetch(`${process.env.REACT_APP_API_URL}pages?per_page=100&acf_format=standard`);

    const { acf, content } = page
    const {
        page_accent_colour,
        page_banner_active,
        primary_page_title,
        page_global_sections,
        seo_meta_title,
        seo_meta_description,
        seo_canonical_url,
        seo_schema
    } = acf


    const getSection = (section) => {

        const sections = {
            'about'             : AboutSection,
            'member-benefits'   : MemberBenefitsSection,
            'help-centre'       : HelpSection,
            'contact'           : ContactSection,
            'clients'           : ClientSection,
            'vacancies'         : VacanciesSection,
            'client-sites'      : ClientSitesSection,
        }

        return (section) ? sections[section] : false ;
    }

    return (
        <>
            <Helmet>
                <title>{seo_meta_title ? seo_meta_title : 'Reset Compliance Systems' }</title>
                {seo_meta_title ? <meta property="og:title" content={seo_meta_title} /> : <meta property="og:title" content="Reset Compliance Systems" /> }
                <meta name="description" property="og:description" content={seo_meta_description ? seo_meta_description : 'Reset Compliance Systems is a competence-verification scheme that saves time and resources in the process of admitting third-party contractors onto any site, ensuring compliance with HSE and CDM obligations.'} />
                <meta name="keywords" content="Reset, rcscard, Reset Compliance Systems, Contractor, Contractor Management, CDM, HSE, Building regulations, Health and Safety, Credentials, Management Solutions, Control of Contractors, Estates Management, Facilities Management" />
                <meta property="robots" content="index, follow" />
                {seo_canonical_url && <link rel="canonical" href={seo_canonical_url} />}
                {seo_schema && <script type="application/ld+json">{seo_schema}</script>}
            </Helmet>

            { page_banner_active && <BannerSection accent={page_accent_colour} banner={acf} /> }

            { primary_page_title && content.rendered && <PrimaryContent accent={page_accent_colour} title={primary_page_title} content={content.rendered} /> }

            { options && <ContactCards options={options} /> }

            { options && page_global_sections && page_global_sections.map(( section, index ) => {

                let Section = getSection(section.page_global_section);

                return (
                    <Section key={index} options={options} pages={pages} backgroundColour={section.page_global_section_background_colour } />
                )
            })}
        </>
    );
}

export default ContactsPage;
