//Components
import Icon from "./Icon";

export default function Loading({ color }) {
    return (
        <div className="flex flex-col items-center justify-center py-36 z-50 fixed left-0 top-0 w-full h-screen bg-white">
            <Icon className="animate-spin" name="loader-4-line" size="6xl" color={color} />
        </div>
    );
}
