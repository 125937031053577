//React
import {Link} from "react-router-dom";

//Components
import {decode} from "html-entities";
import Icon from "./Icon";

export default function Button(props) {

    const {
        target,
        accent,
        url,
        title,
        iconName,
        iconSize,
        type,
        state,
        margin
    } = props;

    return (
        <>
            {type && type === "for" &&
                <p>
                    {target === '_blank' ?
                        <a
                            href={url}
                            className={`btn hover:btn--${accent} mb-4 `}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {decode(title)}
                            <Icon name={iconName} size={iconSize}/>
                        </a>
                        :
                        <Link
                            to={url}
                            className={`btn hover:btn--${accent} mb-4 `}
                            target={target ? target : '_self'}
                            rel="noreferrer noopener"
                        >
                            {decode(title)}
                            <Icon name={iconName} size={iconSize}/>
                        </Link>
                    }
                </p>
            }

            {type === "PScontent" &&
            <p>
                {target === '_blank' ?
                    <a
                        href={url}
                        className={`btn btn--block sm:btn--inline hover:btn--${accent}`}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        {decode(title)}
                        <Icon name={iconName} size={iconSize}/>
                    </a>
                    :
                    <Link
                        to={url}
                        className={`btn btn--block sm:btn--inline hover:btn--${accent}`}
                        target={target ? target : '_self'}
                        rel="noreferrer noopener"
                    >
                        {decode(title)}
                        <Icon name={iconName} size={iconSize}/>
                    </Link>
                }
            </p>
            }

            {type === "banner" &&
                <p>
                    {target === '_blank' ?
                        <a
                            href={url}
                            className="btn btn--white"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {decode(title)}
                            <Icon name={iconName} size={iconSize}/>
                        </a>
                        :
                        <Link
                            to={url}
                            state={state}
                            className="btn btn--white"
                            target={target ? target : '_self'}
                            rel="noreferrer noopener"
                        >
                            {decode(title)}
                            <Icon name={iconName} size={iconSize}/>
                        </Link>
                    }
                </p>
            }

            {type === "journal" &&
                <p>
                    {target === '_blank' ?
                        <a
                            href={url}
                            className={`btn btn--sm btn--white hover:btn--${accent}`}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {decode(title)}
                            <Icon name={iconName} size={iconSize}/>
                        </a>
                        :
                        <Link
                            to={url}
                            className={`btn btn--sm btn--white hover:btn--${accent}`}
                            target={target ? target : '_self'}
                            rel="noreferrer noopener"
                        >
                            {decode(title)}
                            <Icon name={iconName} size={iconSize}/>
                        </Link>
                    }
                </p>
            }

            {type === "slider" &&
                <p>
                    {target === '_blank' ?
                        <a
                            href={url}
                            className={`btn btn--sm btn--white mt-4 sm:mt-6`}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {decode(title)}
                            <Icon name={iconName} size={iconSize}/>
                        </a>
                        :
                        <Link
                            to={url}
                            state={state}
                            className={`btn btn--sm btn--white mt-4 sm:mt-6`}
                            target={target ? target : '_self'}
                            rel="noreferrer noopener"
                        >
                            {decode(title)}
                            <Icon name={iconName} size={iconSize}/>
                        </Link>
                    }
                </p>
            }

            {type === "subscribe" &&
                <p>
                    {target === '_blank' ?
                        <a
                            href={url}
                            className="btn btn--sm btn--orange xl:btn--white lg:hover:btn--orange mt-4 sm:mt-0 xl:mt-4 btn--block w-full sm:w-auto"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {decode(title)}
                            <Icon name={iconName} size={iconSize}/>
                        </a>
                        :
                        <Link
                            to={url}
                            className="btn btn--sm btn--orange xl:btn--white lg:hover:btn--orange mt-4 sm:mt-0 xl:mt-4 btn--block w-full sm:w-auto"
                            target={target ? target : '_self'}
                            rel="noreferrer noopener"
                        >
                            {decode(title)}
                            <Icon name={iconName} size={iconSize}/>
                        </Link>
                    }
                </p>
            }

            {type === "not_found" &&
                <p>
                    <Link
                        to={url}
                        className="btn btn--green mt-8"
                        target={target ? target : '_self'}
                    >
                        {decode(title)}
                        <Icon name={iconName} size={iconSize}/>
                    </Link>
                </p>
            }

            {type === "posts" &&
                <p>
                    {target === '_blank' ?
                        <a
                            href={url}
                            className={`btn btn--sm btn--white hover:btn--orange mt-4 sm:mt-6`}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {decode(title)}
                            <Icon name={iconName} size={iconSize}/>
                        </a>
                        :
                        <Link
                            to={url}
                            state={state}
                            className={`btn btn--sm btn--white hover:btn--orange mt-4 sm:mt-6`}
                            target={target ? target : '_self'}
                            rel="noreferrer noopener"
                        >
                            {decode(title)}
                            <Icon name={iconName} size={iconSize}/>
                        </Link>
                    }
                </p>
            }

            {type === "about" &&
                <p>
                    {target === '_blank' ?
                        <a
                           href={url}
                           className="btn hover:btn--orange mt-6"
                           target="_blank"
                           rel="noreferrer noopener"
                        >
                            {decode(title)}
                            <Icon name={iconName} size={iconSize}/>
                        </a>
                        :
                        <Link
                            to={url}
                            className="btn hover:btn--orange mt-6"
                            target={target ? target : '_self'}
                            rel="noreferrer noopener"
                        >
                            {decode(title)}
                            <Icon name={iconName} size={iconSize}/>
                        </Link>
                    }
                </p>
            }

            {type === "section" &&
                <p>
                    {target === '_blank' ?
                        <a
                            href={url}
                            className={`btn hover:btn--${accent} ${margin}`}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {decode(title)}
                            <Icon name={iconName} size={iconSize}/>
                        </a>
                        :
                        <Link
                            to={url}
                            className={`btn hover:btn--${accent} ${margin}`}
                            target={target ? target : '_self'}
                        >
                            {decode(title)}
                            <Icon name={iconName} size={iconSize}/>
                        </Link>
                    }
                </p>
            }

            {type === "pdf" &&
                <p>
                    {target === '_blank' ?
                        <a
                            href={url}
                            className={`btn btn--block sm:btn--inline hover:btn--${accent}`}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {decode(title)}
                            <Icon name={iconName} size={iconSize}/>
                        </a>
                        :
                        <Link
                            to={url}
                            className={`btn btn--block sm:btn--inline hover:btn--${accent}`}
                            target={target ? target : '_self'}
                        >
                            {decode(title)}
                            <Icon name={iconName} size={iconSize}/>
                        </Link>
                    }
                </p>
            }
        </>
    );
}
