//Components
import Card from "../card/Card";
import Button from "../Button";

const VacanciesSection = ({ backgroundColour, options }) => {

    const {
        section_vacancies_sub_title,
        section_vacancies_title,
        section_vacancies_text,
        section_vacancies_button,
        section_vacancies_image,
        section_vacancies_cards: cards
    } = options.acf;

    return (
        <section className={`${backgroundColour && `bg-${backgroundColour}`} py-20`}>
            <div className="container grid lg:grid-cols-3 gap-8 lg:gap-12">
                <div>
                    { section_vacancies_sub_title && <p className="mb-4 text-xl font-bold before:content-[''] before:w-12 before:h-0.5 before:bg-orange before:block before:mb-3">{section_vacancies_sub_title}</p> }

                    { section_vacancies_title && <h2 className="text-3xl mb-8">{section_vacancies_title}</h2> }

                    { section_vacancies_text && <div dangerouslySetInnerHTML={{ __html: section_vacancies_text }} /> }

                    { section_vacancies_button &&
                        <Button
                            type={"section"}
                            url={section_vacancies_button.url}
                            accent={"orange"}
                            margin={`mt-6`}
                            title={section_vacancies_button.title}
                            iconName={"arrow-right-line"}
                            iconSize={"xl"}
                            target={section_vacancies_button.target ? section_vacancies_button.target : '_self'}
                        />
                    }

                    <div className="mt-10 max-w-xs">
                        {cards && cards.map((card, index) =>
                            <Card
                                key={index}
                                phone={card.section_vacancies_card_phone && card.section_vacancies_card_phone}
                                email={card.section_vacancies_card_email && card.section_vacancies_card_email}
                                bgColour={`bg-slate-dark hover:bg-slate-dark/80 transition-all`}
                                type={'contact'}
                                title={card.section_vacancies_card_title && card.section_vacancies_card_title}
                                text={card.section_vacancies_card_text && card.section_vacancies_card_text}
                                accent={card.section_vacancies_card_accent && card.section_vacancies_card_accent}
                                icon={card.section_vacancies_card_icon && card.section_vacancies_card_icon}
                            />
                        )}
                    </div>

                </div>

                <div className="col-span-2 h-full bg-cover bg-no-repeat bg-center" style={{backgroundImage: `url(${section_vacancies_image.url})`}} />

            </div>
        </section>
    );
}

export default VacanciesSection;
