//React
import { useLocation } from 'react-router-dom';

//Hooks
import { useEffect } from "react";

export default function ScrollToTop ({children}) {

    let location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            {children}
        </>
    );
}
