//Components
import Button from "../Button";
import ClientSiteLogoGrid from "../clientSites/ClientSiteLogoGrid";

const ClientSitesSection = ({ options, backgroundColour }) => {

    const {
        section_client_sites_title,
        section_client_sites_text,
        section_client_sites_button
    } = options.acf;

    return (
        <section className={`${backgroundColour && backgroundColour !== 'white' && `bg-${backgroundColour}`} pt-20 relative before:content[''] `} >
            <div className="container xl:w-2/3 relative">
                <header className="text-center">
                    { section_client_sites_title &&
                        <h2 className="mb-8 before:content-[''] before:w-12 before:h-0.5 before:bg-orange before:block before:mx-auto before:mb-3 text-slate">
                            {section_client_sites_title}
                        </h2>
                    }

                    { section_client_sites_text && <div className="mb-8" dangerouslySetInnerHTML={{ __html: section_client_sites_text }} /> }

                    { section_client_sites_button &&
                        <Button
                            type={"section"}
                            url={section_client_sites_button.url}
                            title={section_client_sites_button.title}
                            iconName={"arrow-right-line"}
                            iconSize={"xl"}
                            target={section_client_sites_button.target ? section_client_sites_button.target : '_self'}
                        />
                    }
                </header>

                <ClientSiteLogoGrid />
            </div>
        </section>
    );
}

export default ClientSitesSection;
