//React
import { useState, useEffect } from 'react';

const useFetch = (url) => {

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState(false);

    // useEffect hook which renders the component every time the state changes with no second argument
    // An empty array as the second argument (dependency array) means that the effect will only run once on load
    // If the dependency array does not hold a state and is empty it will not re-render like mentioned above
    // If the dependency array does hold a state or states, for example, 'blogs' then the effect will only run when the blogs state changes
    useEffect(() => {

        // AbortController is a class that can be used to abort fetch requests
        const abortController = new AbortController();

            // Attach signal to the abortController
            fetch(url, {signal: abortController.signal})
            .then(response => {
                setResponse(response);
                if (!response.ok) {
                    throw new Error('Failed to fetch the data from the resource.');
                }
                return response.json();
            })
            .then(data => {
                setData(data);
                setLoading(false);
                setError(null);
            })
            .catch(error => {
                if(error.name === 'AbortError') {
                    // console.log('Fetch request aborted.');
                } else {
                    setError(error.message);
                    setLoading(false);
                }
            });

        // This is the cleanup function which will run when the component is unmounted
        return () => abortController.abort();

    }, [url]); // add the url as a dependency array to the useEffect hook instead of leaving it blank, so it runs when the url state changes.

    return {data, loading, error, response};
}

export default useFetch;
