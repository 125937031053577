//Components
import AccordionItem from "./AccordionItem";

const Accordion = ({ data: faqs }) => {
    return (
        <div className="accordion">
            {faqs.map(faq =>
                <AccordionItem key={faq.id} id={faq.id} title={faq.title.rendered} content={faq.acf.faq_answer} />
            )}
        </div>
    );
}
export default Accordion;
