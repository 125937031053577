//React
import useFetch from "../hooks/useFetch";

//Components
import Slider from "react-slick";
import Testimonial from "./Testimonial";
import Icon from "./Icon";
import Alert from "./Alert";

function SliderNextArrow({ className, style, onClick }) {
    return (
        <div className={`cursor-pointer z-0 ${className}`} style={{ ...style }} onClick={onClick}>
            <Icon name="arrow-right-line" size="2xl" />
        </div>
    );
}

function SliderPrevArrow({ className, style, onClick }) {
    return (
        <div className={`cursor-pointer z-0 ${className}`} style={{ ...style }} onClick={onClick}>
            <Icon name="arrow-left-line" size="2xl" />
        </div>
    );
}

const TestimonialSlider = () => {

    const {data: testimonials, error } = useFetch(`${process.env.REACT_APP_API_URL}testimonial/?acf_format=standard`);

    var settings = {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: <SliderNextArrow />,
        prevArrow: <SliderPrevArrow />,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    return (
        <>
            { error && <Alert type="error" msg={`Testimonials: ${error}`} /> }

            {testimonials && testimonials.length > 0 && (
            <Slider className={`slider--testimonials pb-12`} {...settings}>
                { testimonials.map( testimonial => <Testimonial
                    key={testimonial.id}
                    title={testimonial.title.rendered}
                    content={testimonial.acf.testimonial_content}  />
                ) }
            </Slider>
            )}
        </>
    );
}

export default TestimonialSlider;
