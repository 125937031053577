//React
import { Link } from "react-router-dom"

function Logo() {
    return (
        <div>
            <Link to={"/"}>
                <img className="h-12" src="../../Assets/Reset-Logo.webp" alt="Reset logo" />
            </Link>
        </div>
    );
}

export default Logo;