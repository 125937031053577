//Components
import Button from "../Button";

const Banner = ({ accent, banner }) => {

    const {
        page_banner_image,
        page_banner_title,
        page_banner_text,
        page_banner_button
    } = banner;

    return (
        <section
            className={`flex items-end ${page_banner_image ? 'min-h-content sm:min-h-160 bg-center bg-cover bg-no-repeat' : 'bg-grey-200 min-h-96'}`}
            style={ page_banner_image ? {backgroundImage: `url(${page_banner_image})`} : {}}
        >
            <div className="w-screen lg:container">
                <div className={`w-full lg:w-1/2 xl:w-2/5 p-8 text-white bg-${accent}/75`}>

                    { page_banner_title && <h1 className="text-4xl">{page_banner_title}</h1> }

                    { page_banner_text && <p className="mb-8">{page_banner_text}</p> }

                    { page_banner_button &&
                        <Button
                            type={`banner`}
                            url={page_banner_button.url}
                            iconName={"arrow-right-line"}
                            iconSize={"xl"}
                            title={page_banner_button.title}
                            target={page_banner_button.target}
                        />
                    }
                </div>
            </div>
        </section>
    );
}

export default Banner;
