//React
import React, {useRef, useState} from "react";

//Components
import Icon from "./Icon";

//Hooks and Helpers
import useSubscribeForm from "../hooks/useSubscribeForm";

import ReCAPTCHA from "react-google-recaptcha";

const FORM_ENDPOINT = "https://app.rcscard.co.uk/api/mailcatcher/v1/create/";

const SubscribeForm = () => {

    const [email, setEmail] = useState('');
    const [recaptchaScore, setRecaptchaScore] = useState("");

    const formElement = useRef(null);

    const additionalData = {
        catchment: "9f5f3fcb-f505-4cfa-8c10-51578987592c",
        token: "b5507016-7da2-4777-a161-1e8042a6a377"
    };

    const { handleSubmit, status, message } = useSubscribeForm({
        form: formElement.current,
        additionalData,
    });

    console.log(status);
    console.log(message);

    if (status === "success") {
        return (
            <>
                <div className="text-2xl">Thank you!</div>
                <div className="text-md">{message}</div>
            </>
        );
    }

    if (status === "error") {
        return (
            <>
                <div className="text-2xl">Oop's!</div>
                <div className="text-md">{message}</div>
            </>
        );
    }

    const recaptchaRef = React.createRef();
    const recaptchaSiteKey = "6LdDBj4oAAAAAAM4rLbZMbqY8rbii91Nft1Q0Jvh";

    const onReCaptchaChange = () => {
       setRecaptchaScore(recaptchaRef.current?.getValue() || "");
    }

    return (

        <form
            action={FORM_ENDPOINT}
            onSubmit={handleSubmit}
            method="POST"
            target="_blank"
            ref={formElement}
            className="sm:flex xl:block"
        >
            <p>{status}</p>
            <input
                className="w-full sm:flex-1 bg-grey-100 px-4 py-3 border-none focus:outline-0 focus:shadow-none focus:border-none focus:ring-0"
                type="email"
                name="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email Address"
            />

            {status !== "loading" && (
            <button
                className="btn btn--sm btn--orange xl:btn--white lg:hover:btn--orange mt-4 sm:mt-0 xl:mt-4 btn--block w-full sm:w-auto">
                Sign Up
                <Icon name="arrow-right-line" size="xl" />
            </button>

            )}

            <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={recaptchaSiteKey ?? ""}
                onChange={onReCaptchaChange}
                className="mt-4"
            />

            <input type="hidden" name="recaptcha" value={recaptchaScore} />

            <script src="https://www.google.com/recaptcha/api.js" async defer></script>

        </form>
    );
};

export default SubscribeForm;
