//Global Sections
import TestimonialSlider from "../TestimonialSlider";

//Components
import ClientLogoGrid from "../clients/ClientLogoGrid";
import Button from "../Button";

const ClientsSection = ({ options, backgroundColour }) => {

    const {
        section_clients_title,
        section_clients_text,
        section_clients_button
    } = options.acf;

    return (
        <section className={`${backgroundColour && backgroundColour !== 'white' && `bg-${backgroundColour}`} pt-20 relative before:content[''] before:absolute before:top-0 before:bg-slate/20 before:w-full before:h-[80%]`} >
            <div className="container xl:w-2/3 relative">
                <header className="text-center">
                    { section_clients_title &&
                        <h2 className="mb-8 before:content-[''] before:w-12 before:h-0.5 before:bg-orange before:block before:mx-auto before:mb-3 text-slate">
                            {section_clients_title}
                        </h2>
                    }

                    { section_clients_text && <div className="mb-8" dangerouslySetInnerHTML={{ __html: section_clients_text }} /> }

                    { section_clients_button &&
                        <Button
                            type={"section"}
                            url={section_clients_button.url}
                            title={section_clients_button.title}
                            iconName={"arrow-right-line"}
                            iconSize={"xl"}
                            target={section_clients_button.target ? section_clients_button.target : '_self'}
                        />
                    }
                </header>

                <ClientLogoGrid />

                <TestimonialSlider />
            </div>
        </section>
    );
}

export default ClientsSection;
