// Components
import Icon from "../Icon";

export default function SecondaryNavigation({ login, register, bookDemo }) {

    const {
        activate_login_links,
        login_accent_colour,
        login_text,
        login_links
    } = login

    const {
        activate_register_links,
        register_accent_colour,
        register_text,
        register_link
    } = register

    const {
        activate_bookDemo_btn,
        bookDemo_button_text,
        bookDemo_link,
        bookDemo_button_fill
    } = bookDemo

    return (
        <nav className="">
            <ul className="flex">
                {activate_bookDemo_btn &&
                    <a href={bookDemo_link} target="_blank" rel="noreferrer noopener" className={`py-2 px-4 flex items-center hover:opacity-80 font-bold md:border-2 md:border-transparent bg-${bookDemo_button_fill} text-white mr-6 group-hover:border-${register_accent_colour} group`}>
                        <span className="hidden md:inline-flex">{bookDemo_button_text}</span>
                        <Icon name="calendar-line" size="2xl" className={`md:ml-3 font-normal transition`}
                        />
                    </a>
                }

                {activate_register_links &&
                    <li className="group relative">
                        <a href="/#"
                           className={`pointer-events-none py-2 px-4 flex items-center font-bold md:border-2 md:border-transparent group-hover:border-${register_accent_colour} group`}>
                            <span className="hidden md:inline-flex">{register_text}</span>
                            <Icon name="pencil-line" size="2xl"
                                  className={`md:ml-3 font-normal group-hover:text-${register_accent_colour} transition`}
                            />
                        </a>

                        {register_link &&
                            <ul className="hidden group-hover:block p-2 absolute left-auto right-0 min-w-52 bg-white drop-shadow-md z-20">
                                {register_link.map((link, index) => {
                                    return (
                                        <li key={index}>
                                            <a className={`after:content-[''] after:h-0.5 after:w-6 after:bg-${link.register_link_accent_colour} after:block after:mt-1 p-3 font-bold`}
                                               target={(link.register_link_button.target) ? link.register_link_button.target : '_self'}
                                               href={link.register_link_button.url}>{link.register_link_button.title}</a>
                                        </li>
                                    )
                                })}
                            </ul>
                        }
                    </li>
                }

                {activate_login_links &&
                    <li className="group relative">
                        <a href="/#" className={`pointer-events-none py-2 px-4 flex items-center font-bold md:border-2 md:border-transparent group-hover:border-${login_accent_colour} group`}>
                            <span className="hidden md:inline-flex">{login_text}</span>
                            <Icon name="user-3-line" size="2xl" className={`md:ml-3 font-normal group-hover:text-${login_accent_colour} transition`} />
                        </a>
                        {login_links &&
                            <ul className="hidden group-hover:block p-2 absolute left-auto right-0 min-w-52 bg-white drop-shadow-md z-20">
                                {login_links.map(( link, index ) => {
                                    return (
                                    <li key={index}>
                                        <a className={`after:content-[''] after:h-0.5 after:w-6 after:bg-${link.login_link_accent_colour} after:block after:mt-1 p-3 font-bold`} target={(link.login_link_button.target) ? link.login_link_button.target : '_self'} href={link.login_link_button.url}>{link.login_link_button.title}</a>
                                    </li>
                                    )
                                })}
                            </ul>
                        }
                    </li>
                }
            </ul>
        </nav>
    );
}