//Components
import CardWrapper from "../card/CardWrapper";
import Card from "../card/Card";
import SubscribeForm from "../SubscribeForm";

//Hooks and Helpers
import useFetch from "../../hooks/useFetch";

const JournalSection = ({backgroundColour, options}) => {

    const {data: journal} = useFetch(`${process.env.REACT_APP_API_URL}journal_issues/?per_page=3&order=desc&acf_format=standard`);

    const {
        section_journal_sub_title,
        section_journal_title,
        section_journal_text,
        section_journal_subscribe_form,
        section_journal_subscribe_form_text
    } = options.acf;

    return (
        <section className={`${backgroundColour && backgroundColour !== 'white' && `bg-${backgroundColour}`} py-20`} >
            <div className="container grid xl:grid-cols-3 gap-8">
                <div>
                    { section_journal_sub_title && <p className="mb-4 text-xl font-bold before:content-[''] before:w-12 before:h-0.5 before:bg-orange before:block before:mb-3">{section_journal_sub_title}</p> }

                    { section_journal_title && <h2 className="text-3xl mb-8">{section_journal_title}</h2> }

                    { section_journal_text && <div className="mb-6" dangerouslySetInnerHTML={{ __html: section_journal_text }} /> }

                    { section_journal_subscribe_form &&
                        <>
                            <SubscribeForm />

                            {section_journal_subscribe_form_text &&
                                <p className="text-sm mt-6" dangerouslySetInnerHTML={{ __html: section_journal_subscribe_form_text }} />
                            }
                        </>
                    }
                </div>
                <div className="xl:col-span-2">

                    {journal && journal.length > 0 && (
                        <CardWrapper className={`md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-0.5 md:auto-rows-fr`}>
                            { journal.map( (issue) =>
                                <Card
                                    key={issue.id}
                                    type={'journal'}
                                    title={issue.title.rendered}
                                    text={issue.acf.journal_issue_text}
                                    image={issue.featured_image_url}
                                    url={issue.acf.journal_issue_link}
                                    accent="orange"
                                />
                            ) }
                        </CardWrapper>
                    )}
                </div>
            </div>
        </section>
    );
}

export default JournalSection;
