//Components
import Alert from "../Alert";

//Hooks and helpers
import useFetch from "../../hooks/useFetch";

const ClientLogoGrid = () => {

    const {data: clients, error } = useFetch(`${process.env.REACT_APP_API_URL}clients/?acf_format=standard&per_page=100`);


    return (
        <>
            { error && <Alert type="error" msg={error} /> }

            {clients && clients.length > 0 && (
            <div className="grid grid-cols-2 lg:grid-cols-4 2xl:grid-cols-8 gap-8 mb-12">
                {clients.sort((a, b) => a.menu_order - b.menu_order).map((client, index) =>
                    <div key={client.id} className="m-auto">
                        <a href={client.acf.client_logo_link} target="_blank" rel="noopener noreferrer"><img src={client.acf.client_logo} alt={client.title.rendered} className="mx-auto max-h-20 max-w-36 transition-all hover:scale-110" /></a>
                    </div>
                ) }
            </div>
            )}
        </>

    );
}

export default ClientLogoGrid;
