//React
import { Fragment } from "react";

//Components
import CardWrapper from "../card/CardWrapper";
import Card from "../card/Card";

//Hooks and Helpers
import { decode } from 'html-entities';

//Font Awesome Library
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const MemberBenefitsSection = ({pages}) => {

    const member_benefits_pages = pages && pages.filter(page => page.parent === 216);

    return (
        <>
        <section>
            <div className={`container grid grid-cols-1 md:grid-cols-3`}>

                <div className={`text-orange text-8xl ml-8`}>
                    <FontAwesomeIcon icon={"fa-thin fa-cubes"} />
                </div>

                <CardWrapper className={`grid grid-cols-1 lg:grid-cols-2 col-start-2 col-span-2`}>

                    {member_benefits_pages && member_benefits_pages.map((benefit, index) =>
                        <Fragment key={index}>

                            {benefit.acf.benefit_card_featured && benefit.acf.benefit_card_featured === true &&

                                <Card
                                    type={'benefit'}
                                    benefitSelector={benefit.acf.benefit_card_image_type}
                                    icon={benefit.acf.benefit_card_icon && benefit.acf.benefit_card_icon}
                                    title={decode(benefit.title.rendered)}
                                    image={benefit.acf.benefit_card_image && benefit.acf.benefit_card_image.url}
                                    benefit0={benefit.acf.benefit_card_list[0] && benefit.acf.benefit_card_list[0].benefit_card_list_item}
                                    benefit1={benefit.acf.benefit_card_list[1] && benefit.acf.benefit_card_list[1].benefit_card_list_item}
                                    benefit2={benefit.acf.benefit_card_list[2] && benefit.acf.benefit_card_list[2].benefit_card_list_item}
                                    benefit3={benefit.acf.benefit_card_list[3] && benefit.acf.benefit_card_list[3].benefit_card_list_item}
                                    url={benefit.slug}
                                    accent={"orange"}
                                />

                            }
                        </Fragment>
                    )}
                </CardWrapper>
            </div>
        </section>
    </>
    );
}

export default MemberBenefitsSection;
