//Components
import CardWrapper from "../card/CardWrapper";
import Card from "../card/Card";
import Button from "../Button";

const HelpSection = ({backgroundColour, options }) => {

    const {
        section_help_centre_sub_title,
        section_help_centre_title,
        section_help_centre_text,
        section_help_centre_button,
        section_help_centre_cards : cards
    } = options.acf;

    return (
        <section className={`${backgroundColour && backgroundColour !== 'white' && `bg-${backgroundColour}`} py-20`} >

            <div className="container grid lg:grid-cols-3 gap-8">
                <div>
                    { section_help_centre_sub_title && <p className="mb-4 text-xl font-bold before:content-[''] before:w-12 before:h-0.5 before:bg-orange before:block before:mb-3">{section_help_centre_sub_title}</p> }

                    { section_help_centre_title && <h2 className="text-3xl mb-8">{section_help_centre_title}</h2> }

                    { section_help_centre_text && <div className="mb-6" dangerouslySetInnerHTML={{ __html: section_help_centre_text }} /> }

                    { section_help_centre_button &&
                        <Button
                            type={"section"}
                            url={section_help_centre_button.url}
                            accent={"orange"}
                            title={section_help_centre_button.title}
                            iconName={"arrow-right-line"}
                            iconSize={"xl"}
                            target={section_help_centre_button.target ? section_help_centre_button.target : '_self'}
                        />

                    }
                </div>

                <div className="lg:col-span-2">
                    <CardWrapper className={`sm:grid-cols-2 xl:grid-cols-3 md:auto-rows-fr`}>
                        {cards && cards.map((card, index) =>
                            <Card
                                key={index}
                                url={card.section_help_centre_card_url && card.section_help_centre_card_url}
                                bgColour={"bg-slate-dark hover:bg-slate-dark/80 transition-all"}

                                type={'about'}
                                title={card.section_help_centre_card_title && card.section_help_centre_card_title}
                                text={card.section_help_centre_card_content && card.section_help_centre_card_content}
                                accent={card.section_help_centre_card_accent && card.section_help_centre_card_accent}
                                icon={card.section_help_centre_card_icon && card.section_help_centre_card_icon}
                            />
                        )}
                    </CardWrapper>
                </div>
            </div>
        </section>
    );
}

export default HelpSection;
