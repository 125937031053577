//Components
import Icon from "./Icon";

export default function Testimonial({ title, content }) {

    return (
        <blockquote className="bg-white py-8 px-6 h-full m-auto border border-grey-100 shadow-md hover:bg-grey-100 transition-all scale-95 hover:scale-100">
            <Icon name="double-quotes-l" size="6xl" className={`text-slate`} />
            <p className="text-base pt-4 w-auto ">{content}</p>
            <p className="title text-lg pt-3 font-semibold text-slate">{title}</p>
        </blockquote>
    );
}
