//Components
import Button from "../Button"

//Hooks and helpers
import {decode} from "html-entities";

const SecondaryContent = ({ data, accent }) => {

    const {
        secondary_title,
        secondary_button,
        secondary_button_2,
        secondary_content
    } = data;

    return (
        <section className="py-8">
            <div className="container grid xl:grid-cols-3 gap-8 lg:gap-12">
                <div>

                    {secondary_title &&
                        <h2 className={`text-2xl before:content-[''] before:w-12 before:h-0.5 before:bg-${accent} before:block before:mb-3`}>
                            {decode(secondary_title)}
                        </h2>
                    }

                    {secondary_button &&
                        <Button
                            type={"PScontent"}
                            url={secondary_button.url}
                            accent={accent}
                            title={secondary_button.title}
                            iconName={"arrow-right-line"}
                            iconSize={"xl"}
                            target={secondary_button.target}
                        />
                    }

                    { secondary_button_2 &&
                        <Button
                            type={"PScontent"}
                            url={secondary_button_2.url}
                            accent={accent}
                            target={secondary_button_2.target}
                            title={secondary_button_2.title}
                            iconName={"arrow-right-line"}
                            iconSize={"xl"}
                        />
                    }

                </div>

                <div className="xl:col-span-2 lg:gap-12" dangerouslySetInnerHTML={{__html: secondary_content}} />

            </div>
        </section>
    );
}

export default SecondaryContent;
