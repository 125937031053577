// React
import { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

// Components
import Icon from "../Icon";

// Hooks & Helpers
import useFetch from "../../hooks/useFetch";
import { decode } from 'html-entities';
import { replaceUrl } from "../../functions/Helpers";

export default function OffCanvasProducts({toggleProducts, handleToggleProducts, fullMenu }) {

    const productId = fullMenu.product_menu;

    // Get menu
    const {data: menu, error} = useFetch(process.env.REACT_APP_MENU_API_URL + productId);

    // Get Pages
    const {data: pages } = useFetch(`${process.env.REACT_APP_API_URL}pages?per_page=100&acf_format=standard`);

    const filteredMenu = menu && menu.filter(menuItem => menuItem.post_parent === 0)

    const doubleFilteredMenu = filteredMenu && filteredMenu.sort((a, b) => a.menu_order - b.menu_order)

    const initialTab = doubleFilteredMenu && doubleFilteredMenu[0]
    const initialTabId = initialTab && initialTab.post_name
    const initialHoverSourceId = initialTab && initialTab.object_id

    const [currentTab, setCurrentTab] = useState()
    const [heading, setHeading] = useState('For Companies')
    const [headingUrl, setHeadingUrl] = useState("companies")
    const [pageToGetHoverAccentFrom, setPageToGetHoverAccentFrom] = useState()
    const [pageParentState, setPageParentState] = useState()

    useEffect(() =>{
        setCurrentTab(initialTabId);
        setPageToGetHoverAccentFrom(initialHoverSourceId && initialHoverSourceId);
        setPageParentState(initialHoverSourceId && initialHoverSourceId)

    }, [initialTabId, initialHoverSourceId])

    const pageParent = pages && pages.filter(page => page.id === parseInt(pageParentState))
    const accent = pageParent && pageParent && `${pageParent[0].acf.page_accent_colour}`
    const subtitle = pageParent && pageParent[0].acf.page_banner_text;
    const hoverAccent = pages && pageToGetHoverAccentFrom && pages.filter(page => page.id === parseInt(pageToGetHoverAccentFrom))[0].acf.page_accent_colour

    const handleTabClick = (id, title, objId, url) =>{
        setHeading(title);
        setHeadingUrl(url);
        setCurrentTab(`${id}`)
        setPageParentState(objId)
    }

    const handleMouseEnter = (objId) =>{
        setPageToGetHoverAccentFrom(objId)
    }

    const headings = menu && menu.filter(menuItem => menuItem.menu_item_parent === "0")


    return (
        <div className={`off-canvas-products bg-white ${toggleProducts ? 'open-products' : ''}`}>
            <div className={`grid grid-cols-3 font-bold items-stretch h-full`}>
                { error && <div>{error}</div> }
                { menu &&
                    <div className={`bg-grey-100 place-content-center h-full p-12 pl-[30%]`}>

                        {headings && headings.map((item, index ) => {

                            const url = replaceUrl(item.url);

                            return (
                                <div key={index} onClick={() => {handleTabClick(item.ID, item.title, item.object_id, url)}}
                                     onMouseEnter={() => handleMouseEnter(item.object_id)}
                                     className={`flex items-center justify-between py-3 px-6 mb-4 cursor-pointer ${parseInt(currentTab) === parseInt(item.ID) ? `bg-${accent} text-white` : 'bg-white'} hover:bg-${hoverAccent} hover:text-white off-canvas-products-header`}>
                                    {decode(item.title)}
                                    <Icon name={`arrow-right-line`} className={`text-lg`} />
                                </div>
                            )
                        })}
                    </div>
                }

                {menu && pages &&
                    <div className={"col-span-2 p-12 relative"}>

                        <div onClick={handleToggleProducts} className={`cursor-pointer lg:flex items-center absolute right-12`}>
                            Close
                            <Icon name={`close-line`} className={`ml-3 text-${accent}`} />
                        </div>

                        <div className={`w-12 h-0.5 bg-${accent} block mb-3`}></div>

                        <Link to={headingUrl} onClick={handleToggleProducts} className={"inline-block align-middle my-auto text-3xl pb-4 group" }>
                            {heading}
                            <Icon name={`arrow-right-line`} className={`transition-all duration-300 text-lg ml-2 group-hover:ml-5 text-${accent}`} />
                        </Link>
                        <div className={" block align-middle my-auto text-2xl pb-8"}>{subtitle}</div>

                        {menu && menu.map((item, index) => {

                            const url = replaceUrl(item.url);

                            let path = item.slug !== 'home' ? url : '/';

                            if(parseInt(item.menu_item_parent) === parseInt(currentTab)) {
                                return (
                                    <NavLink to={path} key={index} onClick={handleToggleProducts}  className={`flex items-center bg-white hover:bg-${accent} hover:text-white w-fit py-4 px-8 nice-hover`}>
                                        {decode(item.title)}
                                        <Icon name={`arrow-right-line`} className={`text-lg ml-2`} />
                                    </NavLink>
                                )
                            } else{
                                return null;
                            }
                        })}
                    </div>
                }
            </div>
        </div>
    );
}