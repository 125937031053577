// React
import { useParams } from "react-router-dom";

// Modules
import {
    TwitterShareButton,
    XIcon,
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon
} from "react-share";

// Components
import Loading from "../Loading";
import Alert from "../Alert";
import Icon from "../Icon";

// Hooks & Helpers
import useFetch from "../../hooks/useFetch";
import { decode } from 'html-entities';
import Helmet from "react-helmet";

const PostSingle = () => {

    let { slug } = useParams();

    const {data: post, error, loading} = useFetch(`${process.env.REACT_APP_API_URL}posts?slug=${slug}&acf_format=standard&_embed`);

    const date = post && new Intl.DateTimeFormat("en-GB", { year: "numeric", month: "long", day: "2-digit"}).format(new Date(post[0].date));

    return (
        <>
            {post &&
                <Helmet>
                    {post[0].acf.seo_meta_title ? <meta property="og:title" content={post[0].acf.seo_meta_title} /> : <meta property="og:title" content="Reset Compliance Systems" /> }
                    <meta name="keywords" content="Reset, rcscard, Reset Compliance Systems, Contractor, Contractor Management, CDM, HSE, Building regulations, Health and Safety, Credentials, Management Solutions, Control of Contractors, Estates Management, Facilities Management" />
                    <meta property="robots" content="index, follow" />
                    <title>{post[0].acf.seo_meta_title ? post[0].acf.seo_meta_title : 'Reset Compliance Systems'}</title>
                    <meta name="description" property="og:description"
                          content={post[0].acf.seo_meta_description ? post[0].acf.seo_meta_description : 'Reset Compliance Systems is a competence-verification scheme that saves time and resources in the process of admitting third-party contractors onto any site, ensuring compliance with HSE and CDM obligations.'}/>
                    {post[0].acf.seo_canonical_url && <link rel="canonical" href={post[0].acf.seo_canonical_url}/>}
                    {post[0].acf.seo_schema && <script type="application/ld+json">{post[0].acf.seo_schema}</script>}
                </Helmet>
            }

            { loading && <Loading color={'orange'} /> }

            { error && <Alert type="error" msg={error} /> }

            { post &&
                <section className="py-24">
                    <div className="container xl:w-256  grid grid-cols-3 gap-12 items-start">
                        <div className={`col-span-3 h-0.5 bg-orange block mb-3`}></div>

                        <div className={"col-span-3"}>
                            <header className="mb-8">
                                <p className={`font-semibold text-orange`}>{post[0]._embedded['wp:term'][0][0].name}</p>
                                <h2 className="leading-normal">{decode(post[0].title.rendered)}</h2>
                            </header>

                            <div className={"mb-12 lg:col-span-1 col-span-3"}>
                                <p className={`font-semibold flex items-center`}><Icon name="calendar-2-line" size="xl" className="mr-4" /> {date}</p>
                            </div>

                            {post[0].featured_image_url &&
                                <div className="post__media mb-8">
                                    <img className={"object-cover w-full"} src={post[0].featured_image_url} alt=""/>
                                </div>
                            }

                            <div className="content font-normal mb-8"  dangerouslySetInnerHTML={{ __html: post[0].content.rendered }} />

                            <footer className={"footer"}>
                                <h5>Share: </h5>
                                <ul className={`list-none flex space-x-4`}>
                                    <li>
                                        <TwitterShareButton url={"twitter.com"}>
                                            <XIcon size={40} round={true}/>
                                        </TwitterShareButton>
                                    </li>

                                    <li>
                                        <FacebookShareButton url={"facebook.com"}>
                                            <FacebookIcon size={40} round={true}/>
                                        </FacebookShareButton>
                                    </li>

                                    <li>
                                        <LinkedinShareButton url={"linkedin.com"}>
                                            <LinkedinIcon size={40} round={true} />
                                        </LinkedinShareButton>
                                    </li>
                                </ul>
                            </footer>
                        </div>
                    </div>
                </section>
            }
        </>
    );
}

export default PostSingle;
