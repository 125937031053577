// React
import { NavLink } from "react-router-dom";
import React from 'react';

// Structure
import Navigation from "./PrimaryNavigation";

// Components
import Icon from "../Icon";

// Hooks & Helpers
import useFetch from "../../hooks/useFetch";
import { decode } from 'html-entities';
import { replaceUrl } from "../../functions/Helpers";

function OffCanvas({toggle, handleToggle, handleToggleProducts, openProducts, fullMenu}) {

    const primaryId = fullMenu.primary_menu;
    const productId = fullMenu.product_menu;

    const {data: menu, error} = useFetch(process.env.REACT_APP_MENU_API_URL + primaryId)
    const {data: pmenu, error: perror} = useFetch(process.env.REACT_APP_MENU_API_URL + productId)

    return (
        <div className={`off-canvas ${toggle ? 'open' : ''}`}>

            { error && <div>{error}</div> }

            { !error && menu && (
                <Navigation>

                    { menu.map( (item, index) => {

                        const url = replaceUrl(item.url);

                        return ((url === 'home/') ?

                            <NavLink key={index} to="/"   className={({ isActive }) => isActive ? 'text-orange p-2 mx-2 cursor-pointer hover:text-orange/75' : 'p-2 mx-2 cursor-pointer hover:text-black/50'} onClick={handleToggle}>
                                <Icon name="home-6-line" size="2xl" />
                            </NavLink>
                                :
                                (item.classes[0] === 'products') ?
                                    <button key={index} onClick={handleToggleProducts} className={`${openProducts ? 'text-black/50' : 'text-black'} transition-all duration-300 hover:text-black/50 p-2 mx-2 font-bold cursor-pointer hidden lg:block`}>Products</button>
                                    :
                                    <NavLink
                                        key={index}
                                        to={(url === 'home') ? '/' : url}
                                        className={ ({ isActive }) => (isActive ? 'text-orange p-2 mx-2 font-bold hover:text-orange/75' : 'p-2 mx-2 font-bold hover:text-black/50') }
                                        onClick={handleToggle}
                                    >
                                        {decode(item.title)}
                                    </NavLink>
                        )
                    })}
                </Navigation>
            )}

            { !perror && pmenu && (
                <Navigation>
                    {pmenu.map((item, index) => {
                        const url = replaceUrl(item.url);

                        return ( parseInt(item.menu_item_parent) === 0 &&
                            <NavLink
                                key={index}
                                to={(url === 'home') ? '/' : url}
                                className={({ isActive }) => isActive ? 'lg:hidden text-orange p-2 mx-2 font-bold hover:text-orange/75' : 'lg:hidden p-2 mx-2 font-bold hover:text-black/50' }
                                onClick={handleToggle}
                            >
                                {decode(item.title)}
                            </NavLink>
                        )
                    })}
                </Navigation>
            )}
        </div>
    );
}




export default OffCanvas;