//Hooks and helpers
import {decode} from "html-entities";

const BriefBenefitsSection = ({ data, accent }) => {

    const {
        brief_benefits_title,
        brief_benefits_section_title,
        brief_benefits_section_subtitle,
        brief_benefits_section_text,
        brief_benefits_section_list
    } = data;

    return (
        <section className="py-10">
            <div className="container grid xl:grid-cols-3 gap-8 lg:gap-12">
                <div>
                    <h2 className={`text-3xl before:content-[''] before:w-12 before:h-0.5 before:bg-${accent} before:block before:mb-3`}>{decode(brief_benefits_title)}</h2>
                </div>
                <div className="lg:col-span-2 bg-grey-100 py-4 px-8 grid lg:grid-cols-3 items-start gap-8">
                    <div className="bg-white py-5 px-4 -ml-8">
                        <p className={`text-2xl font-bold mb-1 before:content-[''] before:w-12 before:h-0.5 before:bg-${accent} before:block before:mb-3`}>{decode(brief_benefits_section_title)}</p>
                        <p className={`text-xl font-semibold`}>{brief_benefits_section_subtitle}</p>
                        <p className={`text-sm`}>{brief_benefits_section_text}</p>
                    </div>
                    <div className="lg:col-span-2 my-3">
                        {brief_benefits_section_list &&
                            <ul className="list-none w-full">
                                {brief_benefits_section_list.map((list_item, index) =>
                                    <li key={index} className={`border-b border-b-${accent} py-3`}>{list_item.brief_benefit_section_list_item}</li>
                                )}
                            </ul>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BriefBenefitsSection;
