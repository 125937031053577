//Components
import CardWrapper from "../card/CardWrapper.js";
import Card from "../card/Card";
import Button from "../Button";

//Hooks and helpers
import {decode} from "html-entities";

const AboutSection = ({options, backgroundColour, pages}) => {

    const {
        section_about_sub_title,
        section_about_title,
        section_about_text,
        section_about_button,
        section_about_feature_image
    } = options.acf;

    const aboutPages = pages && pages.filter(page => page.acf.toggle_featured === true );

    return (
        <section className={`${backgroundColour && `bg-${backgroundColour}`} py-20`}>
            {options &&
            <div className="container grid lg:grid-cols-3 gap-12 xl:gap-20">
                <div>
                    {section_about_sub_title &&
                        <p className="mb-4 text-xl font-bold before:content-[''] before:w-12 before:h-0.5 before:bg-orange before:block before:mb-3">{section_about_sub_title}</p>}

                    {section_about_title && <h2 className="text-3xl mb-8">{decode(section_about_title)}</h2>}

                    {section_about_text && <div dangerouslySetInnerHTML={{__html: section_about_text}}/>}

                    {section_about_button &&
                        <Button type={"about"}
                            url={section_about_button.url}
                            title={`Read more`}
                            iconName={"arrow-right-line"}
                            iconSize={"xl"}
                            target={section_about_button.target ? section_about_button.target : '_self'}
                        />
                    }
                </div>

                <div className="lg:col-span-2">
                    <div className="grid grid-cols-1 xl:grid-cols-5 xl:gap-0.5">

                        <div className="xl:block bg-cover bg-no-repeat bg-center row-auto h-32 xl:h-auto"
                             style={{backgroundImage: `url(${section_about_feature_image.url})`}}></div>

                        <CardWrapper className={`col-span-4 sm:grid-cols-2 md:auto-rows-fr`}>
                            {aboutPages && aboutPages.sort((a, b) => a.acf.featured_order - b.acf.featured_order).map((aboutPage, index) =>
                                <Card
                                    key={index}
                                    type={'about'}
                                    subtitle={aboutPage.acf.featured_subtitle}
                                    title={aboutPage.acf.featured_title && aboutPage.acf.featured_title}
                                    text={aboutPage.acf.featured_content && aboutPage.acf.featured_content}
                                    accent={ aboutPage.acf.page_accent_colour }
                                    url={aboutPage.acf.for_card_button.url}
                                />
                            )}
                        </CardWrapper>
                    </div>
                </div>
            </div>
            }
        </section>

    );
}

export default AboutSection;
