//React
import { Fragment, useState } from "react";

//Components
import CardWrapper from "../card/CardWrapper";
import Card from "../card/Card";
import CardContent from "../card/CardContent";
import Button from "../Button";

const CompaniesSection = ({backgroundColour, options, pages}) => {

    const parentPages = pages && pages.sort((a, b) => a.menu_order - b.menu_order)
        .filter(page => (page.parent === 0) && (page.template === "for-pages.php"))

    const childPages = pages && pages.filter(page => (page.parent !== 0) && (page.template === "for-pages.php"))

    const companiesChildren = childPages.filter(page => (page.parent === parentPages[0].id))

    const [tab, setTab] = useState(null)

    const {
        section_company_product_sub_title,
        section_company_product_title,
        section_company_product_text,
        section_company_product_button
    } = options.acf;

    function assignStartingRow(order) {
        let startingRow;

        switch (order) {
            case 0:
                startingRow = "row-start-2 lg:row-start-2";
                break;
            case 50:
                startingRow = "row-start-3 sm:row-start-2 lg:row-start-2";
                break;
            case 100:
                startingRow = "row-start-4 sm:row-start-3   lg:row-start-2";
                break;
            case 150:
                startingRow = "row-start-5 sm:row-start-3  lg:row-start-3";
                break;
            case 200:
                startingRow = "row-start-6 sm:row-start-4  lg:row-start-3";
                break;
            case 250:
                startingRow = "row-start-7 sm:row-start-4  lg:row-start-3";
                break;
            case 300:
                startingRow = "row-start-8 sm:row-start-5  lg:row-start-4";
                break;
            case 350:
                startingRow = "row-start-9 sm:row-start-5  lg:row-start-4";
                break;
            case 400:
                startingRow = "row-start-10 sm:row-start-6  lg:row-start-4";
                break;
            case 450:
                startingRow = "row-start-11 sm:row-start-6  lg:row-start-5";
                break;
            case 500:
                startingRow = "row-start-12 sm:row-start-7  lg:row-start-5";
                break;
            case 550:
                startingRow = "row-start-13 sm:row-start-7  lg:row-start-5";
                break;
            case 600:
                startingRow = "row-start-14 sm:row-start-8  lg:row-start-6";
                break;
            case 650:
                startingRow = "row-start-15 sm:row-start-8  lg:row-start-6";
                break;
            case 700:
                startingRow = "row-start-16 sm:row-start-9  lg:row-start-6";
                break;
            case 750:
                startingRow = "row-start-17 sm:row-start-9  lg:row-start-7";
                break;
            case 800:
                startingRow = "row-start-18 sm:row-start-10  lg:row-start-7";
                break;
            default:
                startingRow = "row-start-2 lg:row-start-2"
        }

        return startingRow;
    }

    function handleClick(index) {
        if(tab === index){
            setTab(null);
        } else {
            setTab(index);

        }
    }

    return (
        <section className={`${backgroundColour && `bg-${backgroundColour}`} py-20`}>

            <div className="container grid lg:grid-cols-3 gap-8">
                <div className={"w-4/5"}>
                    {section_company_product_sub_title &&
                        <p className="mb-4 text-xl font-bold before:content-[''] before:w-12 before:h-0.5 before:bg-blue before:block before:mb-3">{section_company_product_sub_title}</p>}

                    {section_company_product_title &&
                        <h2 className="text-3xl mb-8">{section_company_product_title}</h2>}

                    {section_company_product_text &&
                        <div className="mb-6 text-sm" dangerouslySetInnerHTML={{__html: section_company_product_text}}/>}

                    {section_company_product_button &&
                        <Button
                            type={"section"}
                            url={section_company_product_button.url}
                            accent={"blue"}
                            title={section_company_product_button.title}
                            iconName={"arrow-right-line"}
                            iconSize={"xl"}
                            target={section_company_product_button.target ? section_company_product_button.target : '_self'}
                        />
                    }
                </div>
                <div className="lg:col-span-2">

                    <CardWrapper className={` grid-cols-1 sm:grid-cols-2 lg:grid-cols-3`}>

                        {companiesChildren.sort((a, b) => a.menu_order - b.menu_order).map((page, index) =>
                            <Fragment key={index}>
                                {page.acf.for_card_activate &&
                                    <Card
                                        key={index}
                                        handleClick={handleClick}
                                        url={'/'}
                                        bgColour={"bg-slate hover:bg-slate/80 transition-all"}
                                        type={'for'}
                                        svgUrl={page.acf.for_card_icon}
                                        title={page.acf.for_card_title && page.acf.for_card_title}
                                        accent={page.acf.page_accent_colour && page.acf.page_accent_colour}
                                        icon={page.acf.for_card_icon_name}
                                        id={index}

                                    />}
                            </Fragment>
                        )}

                        {companiesChildren.sort((a, b) => a.menu_order - b.menu_order).map((page, index) =>
                            tab === index &&
                            <CardContent className={` col-span-1 sm:col-span-2 lg:col-span-3 bg-white p-8 ${assignStartingRow(page.menu_order)}`}
                                key={index}
                                id={index}
                                page_accent_colour={page.acf.page_accent_colour}
                                primary_page_title={page.acf.primary_page_title}
                                for_card_sub_title={page.acf.for_card_sub_title}
                                for_card_text={page.acf.for_card_text}
                                for_card_button={page.acf.for_card_button}
                                page_banner_button={page.acf.page_banner_button}
                                menu_order={page.menu_order}
                            />
                        )}
                    </CardWrapper>
                </div>
            </div>
        </section>
    );
}

export default CompaniesSection;
