//React
import {useState} from "react";

//Components
import Icon from "../Icon";

//Module Imports
import {decode} from "html-entities";

const AccordionItem = ({ title, content, id, ...props }) => {

    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => setIsOpen(!isOpen);

    return (
        <div className="accordion__item border-b border-b-grey-200 last:border-b-0">
            <header className="flex items-start cursor-pointer py-4" onClick={handleToggle}>
                <Icon name={isOpen ? 'subtract-line' : 'add-line'} size="xl" className="mr-4" />
                <p className="text-lg font-semibold">{decode(title)}</p>
            </header>
            {isOpen && <div className="mb-4 leading-7" dangerouslySetInnerHTML={{ __html: content }}></div>}
        </div>
    );
}
export default AccordionItem;
