
import { useEffect } from 'react';

const VerifyRedirect = () => {
    return (
        useEffect(() => {
            window.location.href = 'https://app.rcscard.co.uk';
        }, [])            
    );
};

export default VerifyRedirect;
