// Components
import PostSlider from "../PostSlider";
import Button from "../Button";

// Sections
import BannerSection from "../slides/BannerSection";
import PrimaryContent from "../slides/PrimaryContent";

// Global Sections
import AboutSection from "../slides/AboutSection";
import MemberBenefitsSection from "../slides/MemberBenefitsSection";
import HelpSection from "../slides/HelpSection";
import ContactSection from "../slides/ContactSection";
import ClientSection from "../slides/ClientSection";
import VacanciesSection from "../slides/VacanciesSection";
import ClientSitesSection from "../slides/ClientSiteSection";

//Hooks and Helpers
import { decode } from 'html-entities';
import useFetch from "../../hooks/useFetch";

//helmet SEO
import Helmet from "react-helmet";


const Posts = ({ page, options }) => {

    const { acf, content } = page

    const {data: posts } = useFetch(`${process.env.REACT_APP_API_URL}posts/?per_page=100&acf_format=standard`);

    const {
        page_accent_colour,
        page_banner_active,
        primary_page_title,
        page_global_sections,
        seo_meta_title,
        seo_meta_description,
        seo_canonical_url,
        seo_schema
    } = acf;


    const featuredPosts = posts && posts.filter(post => post.acf.featured_post === true);

    const standardPosts = posts && posts.filter(post => post.acf.featured_post === false || !post.acf);

    const getSection = (section) => {

        const sections = {
            'about'             : AboutSection,
            'member-benefits'   : MemberBenefitsSection,
            'help-centre'       : HelpSection,
            'contact'           : ContactSection,
            'clients'           : ClientSection,
            'vacancies'         : VacanciesSection,
            'client-sites'      : ClientSitesSection,
        }

        return (section) ? sections[section] : false ;
    }

    return (
        <>
            <Helmet>
                <title>{seo_meta_title ? seo_meta_title : 'Reset Compliance Systems' }</title>
                {seo_meta_title ? <meta property="og:title" content={seo_meta_title} /> : <meta property="og:title" content="Reset Compliance Systems" /> }
                <meta name="description" property="og:description" content={seo_meta_description ? seo_meta_description : 'Reset Compliance Systems is a competence-verification scheme that saves time and resources in the process of admitting third-party contractors onto any site, ensuring compliance with HSE and CDM obligations.'} />
                <meta name="keywords" content="Reset, rcscard, Reset Compliance Systems, Contractor, Contractor Management, CDM, HSE, Building regulations, Health and Safety, Credentials, Management Solutions, Control of Contractors, Estates Management, Facilities Management" />
                <meta property="robots" content="index, follow" />
                {seo_canonical_url && <link rel="canonical" href={seo_canonical_url} />}
                {seo_schema && <script type="application/ld+json">{seo_schema}</script>}
            </Helmet>

            { page_banner_active && <BannerSection accent={page_accent_colour} banner={acf} /> }

            { primary_page_title && content.rendered && <PrimaryContent accent={page_accent_colour} title={primary_page_title} content={content.rendered} /> }

            { featuredPosts && featuredPosts.length > 0 &&
                <section className="py-20">
                    <div className="container grid grid-cols-1 lg:grid-cols-3 gap-8">
                        <div>
                            <h2 className="text-3xl before:content-[''] before:w-12 before:h-0.5 before:bg-orange before:block before:mb-3">Featured Posts</h2>
                        </div>
                        <div className="lg:col-span-2">
                            <PostSlider posts={featuredPosts} accent={page_accent_colour} />
                        </div>
                    </div>
                </section>
            }

            { standardPosts && standardPosts.length > 0 &&
                <section className="bg-grey-200 py-20">
                    <div className="container grid xl:grid-cols-3 gap-8">
                        <div>
                            <h2 className="text-3xl before:content-[''] before:w-12 before:h-0.5 before:bg-orange before:block before:mb-3">Posts</h2>
                        </div>
                        <div className="lg:col-span-2 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 2xl:grid-cols-3 gap-1">
                            {standardPosts.map(post =>
                                <div key={post.id} className="card bg-white p-6 min-h-56 flex flex-col justify-between items-start">
                                    <h5 className="mb-2 before:content-[''] before:w-12 before:h-0.5 before:bg-orange before:block before:mb-3">{decode(post.title.rendered)}</h5>

                                    <Button
                                        type={"posts"}
                                        url={`/news/${post.slug}`}
                                        title={`Read more`}
                                        iconName={"arrow-right-line"}
                                        iconSize={"lg"}

                                    />

                                </div>
                            )}
                        </div>
                    </div>
                </section>
            }

            { options && page_global_sections && page_global_sections.map(( section, index ) => {

                let Section = getSection(section.page_global_section);

                return (
                    <Section key={index} options={options} backgroundColour={section.page_global_section_background_colour}/>
                )
            })}
        </>
    );
}

export default Posts;
